@use '../../../abstracts' as *;

.copy-material-modal {
  .copy-material-modal__body {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 2fr 1fr;
    overflow: hidden;
  }

  .copy-material-modal__filter,
  .copy-material-modal__material-view {
    border-right: 0.125rem solid var(--grey-200);
    padding-right: 1rem;
  }

  .copy-material-modal__filter {
    display: flex;
    flex-direction: column;
  }

  .copy-material-modal__material-view {
    min-height: 0;
    display: flex;
    flex-direction: column;

    .scroll-container {
      overflow-y: auto;
    }

    .accordion--material {
      .bc-accordion__headline {
        padding-left: 0.75rem;
        position: sticky;
        top: 0;
      }
    }
  }

  .copy-material-modal__selected-material {
    min-width: 0;

    .no-selection-text {
      align-items: center;

      .icon {
        transform: translateY(0.125rem);
      }
    }
  }

  .selection-btn {
    margin-right: 0.25rem;
  }

  .copy-material-btn {
    display: flex;
    width: 100%;
  }
}
