@use '../abstracts' as *;

.dashboard-navigation {
  text-align: center;
  display: flex;
  flex-direction: column;

  @include mq(medium) {
    flex-direction: row;
    justify-content: space-around;
  }

  .dashboard-link {
    border-bottom: unset;
    color: var(--primary-400);

    .heading-6 {
      margin: 0 0 0.5rem;
      padding: 0;
    }

    @include mq(medium) {
      flex: 1 0 13rem;
    }

    .pill {
      position: absolute;
    }
  }

  @include mq(medium, down) {
    .dashboard-link + .dashboard-link {
      margin-top: 1rem;
    }
  }

  .pill {
    position: absolute;
  }

  .dashboard-link__subtitle {
    margin: 0;
    color: var(--grey-700);
  }
}

.license-activation {
  margin-bottom: 1rem;

  &.panel--default {
    padding: 0;
  }

  .panel__heading {
    padding: 1.5rem;
  }

  .panel__body {
    padding: 0 1.5rem 1.5rem;
  }

  &.license-activation--open {
    .panel__heading {
      background-color: var(--blue-100);
    }

    .license-activation__heading {
      color: var(--heading-color);
    }
  }
}
.license-activation__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-400);
}

.license-activation__trigger {
  .icon {
    margin-right: 0.5rem;
    fill: currentColor;
  }
}

.license-activation__input {
  width: 100%;
}

.license-activation__tips {
  margin-top: 1.5rem;

  .heading-6 {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 0;
  }
}

.user-modules {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 1rem;
}

.open-activate-license {
  cursor: pointer;
  margin-top: 1rem;
  background-color: unset;
  border: unset;
  border-radius: unset;
  transition: transform 0.25s ease;
}

.add-book-button {
  @include button-reset();
  color: var(--blue-800);

  &:hover {
    color: var(--white);

    .add-book-button__icon {
      background-color: var(--primary-400);
      box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
    }
  }

  .add-book-button__icon {
    background-color: var(--grey-300);
    overflow: hidden;
    transition: all 0.15s ease;
    box-shadow: 0 0 0.75rem 0 var(--grey-500);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.5rem auto;
    @include image-circled(6rem);

    @include mq(medium) {
      @include image-circled(7.5rem);
    }

    svg {
      width: 2rem;
      height: 2rem;
      fill: currentColor;
    }
  }
}

.bc-button__button {
  &.action-button--busy {
    box-shadow: 0 0.125rem 0 0 var(--green-500), inset 0 0.1875rem 0 0 var(--green-300);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &:hover {
      background: var(--primary-400);
      box-shadow: 0 0.125rem 0 0 var(--green-500), inset 0 0.1875rem 0 0 var(--green-300);
    }
    &::after {
      animation: preloader 1.3s 0s infinite ease-out;
      background: var(--info-400);
      content: '';
      height: 0.25rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 25%;
    }
  }

  &.action-button--success {
    box-shadow: 0 0.125rem 0 0 var(--green-500);
    span {
      display: block;
      animation: shift-margin 2s 0s infinite ease-out;
      position: relative;
      &:after {
        animation: fade-in-out 2s 0s infinite ease-out;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAzCAYAAAAzSpBQAAAACXBIWXMAABcSAAAXEgFnn9JSAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAetJREFUeNrsmtFRhDAQhnM2QDqQDsQOsANKuBKwg7MCsQLpQKxA7AA7wA64CjCZWUcmbjgImxzJZGf2ZbnbzMf+SS6bO4zjyEK1GxawRbgIF+EiXIQTVgjnIcLlwt+E9wCJ2sHDTVxWqxN+O4m9Y5A+Vu6kgLFQKifl+KHEzsJT4YPPlZNyrJH4EQPzDa5G5CjnWuO7LAtYHaf2LTzTVc0XOA5LfqLEH4S3vu9zNQL2cgnMh8oZydEHuBQ269Vy9EGWmByfloLtuXKl8Gcl9gVyZD7D6eR4D/HNR54Kdv49ybFbnUlWTvF6/LMj8tymn8b/1pnmmwNzDZghYw/CU9OcS1bLV0cSrTXHm9444wVZuqogJsd2a14syEHnrgDJ5TgH5xJQN05Jkd9kYErACsndUL080zdLAZhr5MhdwdkClDl7JF9BOZ+3zg1TQKtyXAs3BzjAirc0T2FbjiZwFIAcPqtabmP/pFy+lwA2yPcqWz8OqPenOUBMjr0NOW6FWwvoVI4UcGsAW5dypIJbAlhqzmjcNhxVm4FD4+YOuaRgFC2Da96sDnADc1biCVnLwMCoG0QZVDDRPF/dwdrTnXinqeD0uon5CjcH+OhKji76llOJfgIwCwXuF7ABsD40uKta/AdRhNuh/QgwAHusV/9tMi8SAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 1.25rem;
        left: -1.5625rem;
        position: absolute;
        top: 0;
        width: 1.25rem;
      }
    }
  }
}

.body--bao {
  .license-activation__heading {
    color: var(--heading-color);
  }

  .add-book-button:not(:hover) {
    color: var(--blue-600);
  }
}

.body--vo {
  .dashboard {
    .user-modules {
      .book-subtitle-wrapper > * {
        display: block;
      }

      .book-button__wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 1rem 0;

        &--pop-in {
          animation: pop-in 0.5s;
        }

        &--pop-out {
          animation: pop-out 0.5s;
        }

        & > button {
          @include button-reset();
          text-align: center;
        }

        .book__title {
          color: var(--blue-900);
        }

        &:hover .book__cover {
          box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
        }

        .pill {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      figure {
        margin: 0;
        align-items: center;

        img {
          width: 8rem;
          height: 12rem;
          object-fit: cover;
        }
      }
    }
  }

  .add-book-button {
    &__icon {
      border-radius: unset;
      border: 0.3125rem solid var(--white);
      box-shadow: 0 0 0.75rem 0 var(--grey-500);
      width: 8rem;
      height: 12rem;
      margin-bottom: 0;

      svg {
        width: 3rem;
        height: 3rem;
      }
    }

    &:hover .add-book-button__icon {
      box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
    }
  }
}

.phased-out-modules {
  box-shadow: unset;

  .bc-notification__notification {
    margin: 1rem 0;
  }
}
