@use '../abstracts' as *;
@use 'material/components/assignments-teacher';
@use 'material/components/book';
@use 'material/components/card';
@use 'material/components/category-card';
@use 'material/components/collapsible-section';
@use 'material/components/medialink-card';
@use 'material/components/options-modal';
@use 'material/components/score-debug';
@use 'material/components/sidebar-form';
@use 'material/components/table-of-contents';
@use 'material/components/wordlist-modal';
@use 'material/components/word-card';
@use 'material/components/copy-material-modal';
@use 'material/module-dashboard';
@use 'material/sidebar';
@use 'material/results';
@use 'material/student-assignments';
@use 'material/search';

.quick-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__user-info {
    display: flex;
    flex-direction: column;
    padding-right: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem 0.0625rem var(--black);
  }

  &__avatar {
    position: relative;

    .pill {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__avatar-dropdown {
    background-color: var(--white);
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: -0.625rem;
    transform: translateY(-50%);
    box-shadow: 0 0.0625rem 0.125rem 0 var(--grey-500);

    svg {
      width: 0.75rem;
      height: 0.75rem;
      fill: var(--body-color);
    }
  }

  &__nav {
    transform: translateY(100%);
    position: absolute;
    bottom: -1rem;
    right: 0;
    z-index: 2;
    background-color: var(--white);
    border-radius: 0.25rem;
    width: max-content;
    padding-inline: 0.5rem;
    box-shadow: 0 0.1875rem 0.375rem var(--grey-500);

    &:before {
      content: '';
      border-style: solid;
      border-width: 0 0.625rem 0.625rem 0.625rem;
      border-color: transparent transparent var(--white) transparent;
      position: absolute;
      top: -0.625rem;
      right: calc(2.5rem - 0.625rem);
    }
  }

  &__nav-link {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    border-bottom: 0.0625rem solid var(--grey-200);
    padding: 0.5rem;

    &--button {
      @include button-reset;
      color: var(--link-color);
      padding: 0.5rem;

      &:hover {
        color: var(--primary-400);
      }
    }

    .pill {
      display: inline-block;
      margin-left: auto;
    }
  }

  &__label {
    padding-inline: 0.5rem;
  }

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    fill: currentColor;
  }
}

#not-licensed-app-modal {
  max-width: 43rem;
}

.col-module-apps {
  @media only screen and (max-width: 1199px) {
    margin-top: 1rem;
  }
}

.module-apps {
  margin-block-start: -1rem;
}

.module-app {
  @include button-reset;
  display: flex;
  gap: 1rem;
  padding-block: 1rem;
  width: 100%;

  & + & {
    border-top: 0.0625rem solid var(--grey-200);
  }

  &__initials {
    width: 2rem;
    height: 2rem;
    border-radius: 0.1875rem;
    color: var(--white);
    @include caption-2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .label,
    .pill {
      margin-block-start: 0.5rem;
    }

    .pill {
      align-self: inherit;
    }
  }

  &__appType {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  &__appType-name {
    font-size: 0.875rem;
  }

  &__appType-logo {
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
  }

  &__name {
    color: var(--heading-color);
    word-break: break-word;
  }

  &:disabled {
    cursor: default;
  }

  &:enabled:hover {
    .module-app__name {
      color: var(--primary-400);
    }
  }
}

.material-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.material-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--primary-text-color);
  border-bottom: 0.0625rem solid var(--blue-200);

  &--disabled {
    &,
    .material-item__button {
      color: var(--grey-700);
    }

    .material-item__icon img {
      opacity: 0.55;
    }
  }

  &:hover {
    background-color: var(--grey-100);
    box-shadow: inset 0.1875rem 0 0 0 var(--primary-400);
  }

  &--static {
    &:hover,
    .material-item__button:hover {
      box-shadow: unset;
      background-color: unset;
    }

    .material-item__name {
      @include ellipsis-text;
    }
  }

  &__actions {
    padding-right: 0.125rem;
    display: flex;
  }

  &__icon {
    display: inline-flex;
    padding-left: 0.75rem;
    box-sizing: content-box;
    font-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;

    img {
      object-fit: cover;
    }
  }

  &__icon,
  &__action,
  &__button--link {
    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: currentColor;
      flex-shrink: 0;
    }

    .bc-svg-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__button {
    @include button-reset;
    flex-grow: 1;

    &:hover {
      color: var(--primary-400);
    }

    &--link {
      justify-content: space-between;
      display: flex;
      padding-right: 0.5rem;
    }

    .material-item--disabled & {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
  }

  &__name {
    display: flex;
  }

  &__name,
  &__button--link {
    padding-block: 0.75rem;
    flex: 1;
    word-break: break-word;
  }

  &__action {
    @include button-reset;
    display: inline-flex;
    padding: 0.375rem;

    .material-item:not(.material-item--disabled) & {
      &:hover {
        color: var(--primary-400);
      }
    }

    &-placeholder {
      width: 36px;
    }
  }

  &__score {
    width: 2.25rem;
    position: relative;

    &-panel {
      display: none;
      position: absolute;
      background-color: var(--white);
      border: 0.0625rem solid var(--grey-200);
      width: max-content;
      border-radius: 0.25rem;
      color: var(--body-color);
      top: -1.25rem;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      box-shadow: 0 0.0625rem 0.125rem 0 var(--grey-500);
      min-width: 28rem;
      z-index: 3;

      pre {
        font-size: 0.875rem;
        line-height: 1;
      }

      &:before {
        content: '';
        background-color: var(--white);
        border: 0.125rem solid var(--grey-200);
        position: absolute;
        bottom: -0.62rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        height: 1rem;
        width: 1rem;
        z-index: 1;
      }
    }

    &-content {
      background-color: var(--white);
      position: relative;
      padding: 1rem;
      z-index: 2;
    }

    &:hover {
      .material-item__score-panel {
        display: block;
      }
    }
  }
}

.btn--open-digibook {
  width: 100%;
  min-height: 5rem;

  .material & {
    padding: 0;
  }

  span {
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-number {
    font-size: 0.7rem;
    min-width: 3.5rem;
  }

  span + span {
    flex-grow: 1;
    border-left: 0.0625rem solid hsla(0, 0%, 100%, 35%);
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.btn--add-material {
  margin-left: auto;
}

.node-header {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include mq('xlarge') {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .panel &.panel__heading {
    padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  }

  [class*='heading'] {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-width: 10ch;

    .node__prefix {
      @include caption-1;
      font-weight: 400;
    }
  }

  .material-filters {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include mq('xlarge') {
      flex-direction: row;
    }
  }

  .bc-select {
    font-weight: 400;

    @include mq('xlarge') {
      width: 12.5rem;
    }
  }

  :lang(fr) .media-content-types-select {
    @include mq('xlarge') {
      width: 14.5rem;
    }

    .bc-select {
      width: 100%;
    }
  }

  .favorite-filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .favorite-filter__label {
      font-weight: normal;
    }
  }
}

.definition-list {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin: 0;

  @include mq(small) {
    display: grid;
    grid-template-columns: 10rem 1fr;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin: 0;

    @include mq(small) {
      margin-inline-start: 1rem;
    }
  }

  .medialink-review-state {
    width: 10rem;
  }
}

.no-favorites-message {
  .bc-svg-icon {
    margin: 0 0.25rem 0.25rem;
    vertical-align: middle;
    font-size: 1.25rem;
  }
}

.body--bao {
  .book__details {
    color: var(--blue-400);
  }

  .module-app {
    &__initials {
      border-radius: 0.625rem;
    }
  }
}

.panel__actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.btn--add-material {
  &.bc-button__button.szh-menu-button {
    padding: 0.5rem;
  }

  .bc-svg-icon {
    width: auto;
    height: 1em;
    font-size: 1.25rem;
  }
}
