@use '../abstracts' as *;

.sidebar {
  position: sticky;
  top: 0;
  color: var(--white);
  display: flex;
  text-align: center;
  min-height: 100vh;
  justify-content: space-between;
  flex-direction: column;

  ul {
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      justify-content: center;

      > button {
        @include button-reset;
        text-align: center;
      }

      > a,
      button {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-direction: column;
        color: currentColor;
        padding: 0.75rem 0.125rem;
        border: 0;
        @include caption-2;
        transition: background-color 0.3s;

        &.active {
          background-color: var(--green-400);
        }

        &:not(.active):hover {
          background-color: var(--blue-800);
        }

        > .sidebar__logo {
          margin: 0;
        }

        .pill {
          position: absolute;
          right: 1.25em;
        }
      }
    }
  }

  svg {
    fill: currentColor;
  }

  &__wrapper {
    background-color: var(--sidebar-bg-color);
    box-shadow: 0.125rem 0 0.5rem 0 hsla(0, 0%, 0%, 0.15);
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__logo {
    margin-block: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  &__text {
    margin: 0.5rem 0 0;
  }
}

.body--bao {
  .sidebar {
    ul {
      > li {
        > a,
        button {
          &:not(.active):hover {
            background-color: var(--blue-300);
          }
        }
      }
    }
  }
}
