@use '../abstracts/mixins' as *;
@use '../layout/app';

.header {
  background: var(--header-bg-color);
  position: relative;
  display: flex;
  color: var(--white);

  .base-container {
    display: grid;
    grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, app.$containerWidth) minmax(1.5rem, 1fr);
    grid-template-areas: '. header-wrapper .';
    width: 100%;
    position: relative;

    .header__wrapper {
      grid-column: header-wrapper;
    }
  }

  &__logo {
    width: 12.5rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    &--space-between {
      justify-content: space-between;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  &__thumbnail {
    $border-width: 0.1875rem;

    @include image-circled(5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: $border-width solid;
    margin-bottom: 0;

    .thumbnail__image {
      filter: none;
    }

    &--logo .thumbnail__image {
      margin: 0.4rem 0 0 0.4rem;
      width: 5rem;
      height: 5rem;
    }

    &--left {
      @include image-circled(5rem + $border-width * 2);
      margin: 0;

      @include mq(medium) {
        @include image-circled(7.5rem + $border-width * 2);
      }
    }
  }

  &__title {
    @include heading-2;
    color: currentColor;
    margin: 0;
  }

  &__subtitle {
    font-size: 1rem;
    line-height: 1.3rem;
    color: currentColor;
  }

  &__titlewrapper {
    text-align: right;
  }

  &__rightwrapper {
    display: flex;
    align-items: center;
  }

  &.header--gradient {
    background: linear-gradient(90deg, var(--header-bg-color) 0%, var(--primary-400) 100%);
  }

  &.header--background {
    background-size: cover;
    background-position: 50%;
    background-blend-mode: luminosity;
    background-color: var(--grey-200);

    &::before {
      background: linear-gradient(90deg, hsla(193, 100%, 17%, 0.85) 0%, hsla(136, 38%, 56%, 0.75) 100%);
      background-repeat: repeat-x;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &.header--personalized {
    .header__thumbnail {
      margin-inline-start: 1rem;
    }

    .header__content {
      flex-direction: row;
      align-items: center;
    }
  }

  &--pelckmans {
    .header__content {
      text-align: left;

      .header__title {
        @include mq(small) {
          font-size: 3.5rem;
          line-height: 4rem;
        }
      }
    }

    .header__wrapper {
      gap: 1rem;
    }

    @include mq(medium, 'down') {
      .thumbnail {
        display: none;
      }
    }
  }

  &--module {
    background-size: cover;
    background-position: center;

    .header__thumbnail-link {
      border: none;
      color: currentColor;
    }
  }
}

.body--bao {
  .header {
    &__title {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    &__subtitle {
      @include uppercase-bold;
      font-weight: 500;
    }

    &--personalized {
      .header__content {
        text-align: left;
        direction: rtl;
      }
    }

    &--background {
      &:before {
        background: linear-gradient(90deg, hsla(193, 50%, 54%, 0.85) 0%, hsla(99, 56%, 49%, 0.75) 100%);
      }
    }
  }
}

.body--vo {
  .header {
    &__logo {
      width: 16rem;
    }
  }
}
