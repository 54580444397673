@use '../abstracts' as *;

.remove-button {
  display: flex;
  transition: color 0.2s ease;

  &:hover {
    color: var(--danger-400);
  }
}
