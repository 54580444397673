@use '../abstracts' as *;

.marketing-notification {
  &.bc-dialog__dialog {
    width: 40rem;
  }

  .bc-dialog__footer {
    display: flex;
    justify-content: space-between;
  }

  .marketing-notification__visual {
    object-fit: cover;
    width: 100%;
    height: 9rem;
    margin-bottom: 1rem;
  }

  .marketing-notification__secondary-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .marketing-notification__close-permanently {
    display: flex;
    align-items: center;

    .bc-checkbox {
      display: flex;
      margin-right: 1rem;
    }
  }

  .marketing-notification__label {
    margin-right: 0.5rem;
    font-size: 0.75rem;
  }

  .marketing-notification__body {
    h1 {
      @include heading-1();
    }

    h2 {
      @include heading-2();
    }

    h3 {
      @include heading-3();
    }
  }
}
