@use '../../abstracts/' as *;

.aside--results {
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    + fieldset {
      margin-block-start: 1rem;
    }

    legend {
      @include heading-6();
      color: var(--heading-color);
      margin-block-end: 0.75rem;
    }

    .bc-formfield__field {
      margin-block-end: 0.5rem;
    }
  }

  .exercise-results-form__date-range {
    display: flex;
    align-items: center;
    padding-block-start: 0.25rem;
    min-height: 1.5rem;

    &--clear {
      @include button-reset;
      padding: 0.25rem;
    }

    .bc-date-range-input {
      &__button {
        font-size: 1rem;
        align-items: center;
      }

      &__date-range-picker {
        z-index: 1;
      }
    }
  }
}

#results-form {
  padding-bottom: 5rem;

  button[type='submit'] {
    width: 100%;
  }
}

.panel-results {
  .panel__body {
    min-height: 4.5rem;
  }
}

.table-results,
.table-sessions {
  @include caption-1;
  width: 100%;

  th {
    padding-block-start: 0;
  }

  tr {
    color: var(--primary-text-color);
  }

  .options {
    width: 6rem;
    text-align: center;

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.table-results {
  .show-details-button {
    @include button-reset;
    padding: 0.25rem;
  }

  .result {
    width: 8.75rem;
  }

  .material-icon {
    width: 1.25rem;

    img {
      width: 1rem;
      height: 1rem;
    }

    .bc-icon,
    .bc-svg-icon {
      margin: 0 auto;
    }

    .bc-svg-icon {
      font-size: 1.25rem;
      display: flex;
    }
  }

  .tr--lvl-1 td:nth-of-type(1) {
    padding-inline-start: 1.5rem;
  }

  .tr--lvl-2 td:nth-of-type(1) {
    padding-inline-start: 2.25rem;
  }

  .tr--lvl-3 td:nth-of-type(1) {
    padding-inline-start: 3rem;
  }

  .tr--lvl-4 td:nth-of-type(1) {
    padding-inline-start: 3.75rem;
  }

  .tr-exercise {
    background: var(--grey-100);
  }
}

.table-sessions {
  .result {
    min-width: 8.75rem;
  }

  .min120 {
    min-width: 7.5rem;
  }
}

.skore-modal {
  .fade-container {
    overflow: auto;
    position: relative;

    &::after {
      background: linear-gradient(0deg, var(--white) 20%, hsla(0, 0%, 100%, 0));
      bottom: 0;
      content: '';
      display: block;
      height: 1.875rem;
      pointer-events: none;
      position: absolute;
      width: 100%;
    }
  }

  .scroll-container {
    height: 15.625rem;
  }

  .title {
    display: block;
    margin-bottom: 1rem;
  }

  .subtitle {
    display: flex;
    align-items: center;
    max-width: 100%;
    gap: 0.5rem;
  }

  .bc-searchbox__wrapper {
    margin-bottom: 0.25rem;
  }

  .bc-accordion {
    font-size: 0.75rem;
    color: (var(--blue-900));

    &__headline {
      background-color: rgba(196, 208, 217, 0.4);
      border-radius: 0.125rem;
      font-size: inherit;
      font-weight: 400;
      margin: 0 0 0.125rem;
      position: relative;
      height: 1.875rem;
      padding: 0.5rem;
    }

    .bc-checkbox {
      margin-right: 0.5rem;
    }

    .student {
      display: flex;
      align-items: center;
      height: 1.875rem;
      padding: 0 0.5rem;
      justify-content: space-between;
    }

    .disabled {
      color: var(--grey-700);
    }

    .bc-accordion__panel .bc-accordion__headline {
      background-color: rgba(196, 208, 217, 0.15);
    }

    .result {
      margin-left: auto;
    }

    .smartschool-logo {
      margin-left: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .status {
    color: var(--white);
    min-height: 30rem;
    background-color: #004457;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .actions {
      display: flex;
    }

    .message {
      margin-bottom: 1rem;
    }

    .spinner {
      height: 4rem;
    }
  }

  .alerts {
    gap: 0.25rem;
    display: grid;
    margin-top: 2rem;

    .bc-alert__alert {
      display: flex;
      align-items: flex-start;
    }

    .icon {
      height: 1.5rem;
      margin-right: 0.25rem;
      vertical-align: text-bottom;
    }
  }
}
