@use '../abstracts' as *;

.helpdesk-target-selector {
  .helpdesk-target-selector__icon {
    fill: var(--green-400);
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
  }

  .helpdesk-target-selector__link {
    border-bottom: none;
    padding: 6.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--left {
      border-bottom: 0.0625rem solid var(--grey-300);

      @include mq(medium) {
        border-right: 0.0625rem solid var(--grey-300);
        border-bottom: none;
      }
    }
  }
}

.helpdesk-detail {
  .helpdesk-about {
    padding: 1rem;
    border: 0.0625rem solid var(--blue-300);
  }
  .category-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem;
  }

  .category {
    &:hover {
      color: var(--green-400);

      li {
        color: var(--body-color);
      }
    }
  }

  .category-list__item {
    border-bottom: 0.0625rem solid var(--grey-300);
    padding: 0.5rem 0;

    .category-list__button {
      @include button-reset();
      width: 100%;

      &--active {
        color: var(--green-400);
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    ul {
      margin: 0.5rem 0 0 0;
      padding: 0 0 0 1rem;
      list-style-type: none;
    }

    li {
      padding: 0.5rem 0;
      line-height: 1.5rem;
      font-size: 0.7rem;
      border-bottom: 0.0625rem solid var(--grey-300);
      display: flex;

      .category-item-list__button {
        @include button-reset();
        font-weight: bold;
        justify-content: space-between;
        display: flex;
        flex: 1;

        &--active {
          font-weight: bold;
          color: var(--green-400);
        }

        &:hover {
          color: var(--green-400);
        }
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}

.member-selector {
  border: 0;
  margin: 0;

  .member-selector__legend {
    color: var(--body-color);
    display: block;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  .member-selector__grid {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;

    .grid-item {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      display: block;
      font-size: 0.75rem;
      line-height: 1.5rem;
      margin: 0;
    }

    .checkbox {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
