@use '../../../abstracts' as *;

.collapsible-section {
  display: flex;
  flex-direction: column;

  &--book-selector {
    .toc {
      position: relative;
    }
  }

  &__header {
    @include button-reset;
    @include caption-2;
    @include uppercase;
    color: var(--white);
    font-weight: bold;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: #77a1aa;
    justify-content: space-between;
    display: flex;
  }
}

.collapsible-exercise-sections--selected {
  .collapsible-section--expanded .toc--sidebar {
    @include no-scrollbar;
    overflow-y: auto;
  }

  &--theme {
    .collapsible-section--expanded .scrollable-area {
      height: calc(100vh - 4.0625rem - 6rem);
    }
  }

  &--chapter {
    .collapsible-section--book-selector {
      position: relative;

      .scrollable-area {
        max-height: calc(100vh - 4.0625rem - 9.9375rem);
      }
    }
  }
}
