@use '../abstracts' as *;

.notification-banner {
  @include caption-2;
  color: var(--white);
  display: flex;
  align-items: center;
  width: 100%;

  &--danger {
    background-color: var(--danger-400);
    border-bottom: 0.0625rem solid var(--danger-500);
  }

  &--info {
    background-color: var(--info-400);
    border-bottom: 0.0625rem solid var(--info-500);
  }

  &--success {
    background-color: var(--success-400);
    border-bottom: 0.0625rem solid var(--success-500);
  }

  &--warning {
    background-color: var(--warning-400);
    border-bottom: 0.0625rem solid var(--warning-500);
  }

  &__icon {
    display: flex;
    padding: 0.5rem;
  }

  &__body {
    flex-grow: 1;
    padding: 0.5rem 0;
    display: flex;
    column-gap: 0.25rem;

    a {
      color: currentColor;
    }
  }

  &__close {
    background-color: transparent;
    border: 0;
    padding: 0.5rem;
    display: flex;

    > span {
      border-bottom: 0.0625rem solid;
      margin-right: 0.5rem;
    }
  }
}
