@use '../../../abstracts/' as *;

.toc {
  &--sidebar {
    .toc-list[data-level] {
      display: none;
    }
    .node--expanded + .toc-list[data-level] {
      display: block;
      background-color: var(--grey-100);
    }

    .node {
      border-bottom: 0.0625rem solid var(--blue-200);
      color: var(--link-color);
      padding: 0.75rem;

      &.node--prefixed {
        align-items: flex-start;
      }

      &.node--exercise .node__icon {
        height: 1rem;
        width: 1rem;
      }

      .node__image {
        display: none;
      }

      .node__prefix {
        margin-bottom: 0.25rem;
      }

      &:hover,
      &.node--active {
        background-color: var(--grey-100);
      }
    }

    .toc-list__item.toc-list__item--overview {
      .node {
        align-items: center;
      }
    }
  }

  &--exercise {
    padding-bottom: 0;
  }

  &--main {
    .toc-list {
      .toc-list__item {
        .node {
          padding-left: 0;
          gap: 1rem;
          align-items: stretch;

          &:hover,
          &.node--active {
            .node__content {
              background-color: var(--grey-100);
            }
          }

          .node__icon {
            display: none;
          }

          .node__content {
            border-bottom: 0.0625rem solid var(--blue-200);
            align-items: center;
            padding: 1rem 0.5rem;
          }

          .node__title {
            @include heading-6;
          }
        }
      }

      .toc-list__item[data-level='0'] {
        > .node {
          .node__title {
            @include heading-4;
          }

          .node__image {
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            background-color: var(--green-600);
            width: 4rem;
            height: 4rem;
            align-self: center;
            margin: 0.5rem 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .toc-list__item[data-level='1'],
      .toc-list__item[data-level='2'] {
        > .node {
          gap: unset;

          .node__details {
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
          }

          .node__prefix {
            background-color: var(--grey-200);
            padding: 0.25rem 0.5rem;
            align-self: flex-start;
            margin-top: 0.125rem;
          }
        }
      }

      .toc-list__item[data-level='2'] {
        display: none;
      }

      .toc-list__item[data-level='0'] .toc-list__item[data-level='1'] {
        margin-left: 5rem;
      }
    }
  }

  &--search {
    .toc-list {
      .toc-list__item[data-level='1'] .toc-list__item[data-level='2'] {
        display: flex;
        .node__content {
          padding-left: 1rem;
        }

        .node__title {
          @include caption-1();
        }
      }
    }
  }
}

.toc-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    &[data-level='1'] {
      .node {
        padding-left: 1.5rem;
      }
    }
    &[data-level='2'] {
      .node {
        padding-left: 2.5rem;
      }
    }
  }
}

.node {
  @include button-reset;
  display: flex;
  gap: 0.5rem;
  width: 100%;

  &.node--active,
  &:hover {
    .node__title {
      color: var(--primary-400);
    }
  }

  &__prefix {
    color: var(--body-color);
    line-height: 1;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    overflow: hidden;
  }

  &__details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__title {
    @include caption-1;
    word-wrap: break-word;
    padding-bottom: 0.2rem;
  }

  &__icon {
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    fill: var(--primary-text-color);
  }

  &__count {
    margin-left: auto;
    color: var(--primary-text-color);
  }
}
