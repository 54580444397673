@use '../abstracts' as *;

.bulk-share-button {
  display: flex;
  height: 2.25rem;

  .bc-button__button {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: none;
    }
  }

  .bc-button__button + .bc-button__button {
    margin: 0;
  }

  .bulk-share-button__multi-select.bc-button__outline {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    padding: 0.5rem 0.75rem;
    color: var(--secondary-400);

    &.bulk-share-button__multi-select--active {
      color: var(--green-400);

      &:hover {
        color: var(--white);
      }
    }
  }

  .bulk-share-button__share {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    border-left: unset;
    box-shadow: unset;

    &[disabled],
    &[disabled]:hover {
      background: unset;
      box-shadow: unset;
      border: 0.0625rem solid;
      border-left: unset;
      color: var(--secondary-400);
    }
  }
}

.tab-panel__bulk-share--hidden {
  display: none;
}
