@use '../abstracts' as *;
@use '../base/helpers' as *;

.assignments-table--my {
  .assignment {
    grid-template-columns: 3.5rem 8.25rem 7rem 1fr 6rem 10.5rem;

    &__name {
      min-width: 8rem;
    }

    &__name,
    &__subject {
      @include ellipsis-text;
    }
  }

  .nt-assignment {
    grid-template-columns: 3.5rem 8.25rem 1fr 6rem 10.5rem;
  }
}
