.material-sidebar {
  position: sticky;
  top: 0;
  min-height: 100vh;
  box-shadow: 0.125rem 0 0.5rem 0 hsla(0, 0%, 0%, 0.15);
  background-color: var(--white);
  display: grid;
  grid-template-columns: 5.625rem 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'back-to-dashboard back-to-dashboard'
    'navigation content';

  .back-to-dashboard {
    background-color: var(--sidebar-bg-color);
    color: var(--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    grid-area: back-to-dashboard;

    .body--bao & {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .p-logo {
    width: 2rem;
    aspect-ratio: 1;
  }

  .material-sidebar-navigation {
    background-color: var(--material-sidebar-bg-color);

    .navigation-link {
      border-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding-block: 0.75rem;
      border-right: 0.0625rem solid transparent;
      position: relative;

      .pill {
        position: absolute;
        right: 1.25rem;
        top: 0.25rem;
      }

      &.active {
        background-color: var(--white);
        border-color: var(--material-sidebar-bg-color);
        color: var(--primary-400);
      }
    }
  }

  .material-sidebar-navigation-list {
    list-style: none;
    margin: unset;
    padding: unset;
  }

  .material-sidebar-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
