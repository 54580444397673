@use '../abstracts' as *;

$sidebarWidth: 5.625rem;
$materialSidebarWidth: 23rem;
$containerWidth: 68.25rem;
$width: min(calc(100% - $sidebarWidth - 1.5rem - 1.5rem), $containerWidth);

.app {
  display: grid;
  grid-template-columns: $sidebarWidth 1fr $width 1fr;
  grid-template-rows: max-content 11.875rem 1.25rem auto auto 1fr;
  grid-template-areas:
    'sidebar notification-banners notification-banners notification-banners'
    'sidebar header1 header2 header3'
    'sidebar overlapping-header1 overlapping-header2 overlapping-header3'
    'sidebar . main .'
    'sidebar . footer .'
    'sidebar . . .';
  min-height: 100vh;

  &.material {
    $width: min(calc(100% - $materialSidebarWidth - 1.5rem - 1.5rem), $containerWidth);

    grid-template-columns: $materialSidebarWidth minmax(1.5rem, 1fr) $width minmax(1.5rem, 1fr);
  }

  > .notification-banners {
    grid-area: notification-banners;
  }

  > header {
    grid-row: header1 / overlapping-header3;
    grid-column: header1 / header4;
  }

  > main {
    grid-row: overlapping-header2 / main;
    grid-column: overlapping-header2;
    z-index: 1;
  }

  > footer {
    grid-area: footer;
  }

  > aside {
    grid-area: sidebar;
    z-index: 2;
  }
}
