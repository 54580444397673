$mikado: 'Mikado';

@font-face {
  font-family: $mikado;
  src: url('https://s3-eu-west-1.amazonaws.com/pakket-p-public/fonts/Mikado/MikadoRegular.woff2') format('woff2');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: $mikado;
  src: url('https://s3-eu-west-1.amazonaws.com/pakket-p-public/fonts/Mikado/MikadoRegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $mikado;
  src: url('https://s3-eu-west-1.amazonaws.com/pakket-p-public/fonts/Mikado/MikadoMedium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: $mikado;
  src: url('https://s3-eu-west-1.amazonaws.com/pakket-p-public/fonts/Mikado/MikadoBold.woff2') format('woff2');
  font-weight: bold;
  font-display: swap;
}
