@use '../../../abstracts' as *;

.word-card {
  width: 100%;
  max-width: 45rem;
  height: 25rem;
  margin: 10vh 0;
  transition: height 0.2s ease-in-out;

  @media (min-width: 992px) {
    width: max(20rem, calc(60vw - 8rem));
  }

  &__front,
  &__back {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 1rem;
    box-shadow: 0 0.1875rem 0.25rem 0 var(--grey-700);
    overflow: hidden;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-block-end: 1.5rem;
  }

  &__word {
    font-size: 1.5rem;
  }

  &__untranslated {
    flex-grow: 1;
    padding: 1rem;

    .word-card__word {
      min-height: 3.5rem;
      margin-bottom: 0;
    }
  }

  &__translated {
    display: flex;
    flex-direction: column;
    flex-basis: 7rem;
    justify-content: center;
    padding: 1rem;
  }

  &__actions {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }

  &__action {
    @include button-reset;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      color: var(--primary-400);
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-y: auto;
    justify-content: space-between;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 12.5rem;

    * {
      margin: 0;
    }
  }

  &__image {
    border: 0.25rem solid var(--white);
    width: 12.5rem;
    height: 7.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0.1875rem 0.25rem 0 rgba(0, 0, 0, 0.5);
    flex-shrink: 0;
  }

  &__synonyms {
    * {
      display: inline-flex;
      gap: 1ch;
      margin: 0;
    }
  }

  &--dark {
    background-color: var(--sidebar-bg-color);
    color: var(--white);
  }

  &--light {
    color: var(--heading-color);

    .word-card__synonyms {
      color: var(--body-color);
    }
  }
}

.wordlist-focus {
  .word-card--collapsed {
    height: 7.5rem;

    .word-card__meta,
    .word-card__image,
    .word-card__synonyms {
      display: none;
    }

    .word-card__front {
      .word-card__translated {
        display: none;
      }
    }

    .word-card__back {
      .word-card__untranslated {
        display: none;
      }
    }
  }
}
