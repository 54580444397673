@use '../abstracts' as *;

.pill {
  border-radius: 1.875rem;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  align-self: center;
  font-weight: 400;
  background-color: var(--grey-200);

  &.pill--info {
    color: var(--grey-100);
    background-color: var(--info-400);
  }

  &.pill--assignments {
    color: var(--grey-100);
    background-color: var(--assignments-color);
  }

  &.pill--success {
    color: var(--grey-100);
    background-color: var(--success-400);
  }

  &.pill--warning {
    color: var(--grey-100);
    background-color: var(--warning-400);
  }

  &.pill--danger {
    color: var(--grey-100);
    background-color: var(--danger-400);
  }

  &.pill--activate {
    color: var(--grey-100);
    background-color: var(--secondary-400);

    &:hover {
      background-color: var(--primary-400);
    }
  }

  &.pill--icon {
    align-items: center;

    .icon {
      display: inline-block;
    }

    .bc-svg-icon {
      font-size: 1rem;
      margin-right: 0.25rem;
    }
  }
}

.body--bao .pill {
  &.pill--activate {
    background-color: var(--info-400);
  }
}
