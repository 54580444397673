@use '../../../abstracts' as *;

.medialink-cards__header {
  .bc-select {
    @include mq('medium') {
      max-width: 13rem;
    }

    select {
      font-weight: normal;
      line-height: initial;
    }
  }
}

.card--medialink {
  position: relative;

  .card__header {
    @include button-reset;
    background-color: var(--sidebar-bg-color);
    padding: 1rem 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &:hover {
      background-color: var(--primary-400);

      .medialink-card__content-type-img {
        color: var(--white);
      }
    }
  }

  .card__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    padding: 0.75rem;

    .medialink-card__footer-link {
      display: flex;
      flex-grow: 1;
      border-bottom: none;
      gap: 0.5rem;
      color: var(--body-color);
      overflow: hidden;

      .icon {
        margin-top: 0.125rem;
        width: 0.625rem;
        height: 0.625rem;
      }

      &:hover {
        color: var(--primary-400);
      }
    }

    .medialink-card__footer-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      span {
        @include caption-2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .pill {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    + .pill {
      top: 2.5rem;
    }
  }

  .medialink-card__content-type-img {
    display: flex;
    margin-top: 1rem;
    color: var(--primary-400);
    font-size: 2.5rem;

    svg,
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
