body {
  background-color: var(--grey-200);
}

a {
  color: var(--link-color);
  text-decoration: none;
  border-bottom: 0.0625rem solid;

  &:hover {
    color: var(--primary-400);
  }
}

ul,
ol {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  padding-inline-start: 1rem;
}

optgroup > option {
  font-weight: normal;
}

.p-list {
  &.p-list--unordered {
    ::marker {
      font-size: 1.25rem;
    }
  }
}

svg {
  fill: currentColor;
}

hr {
  border: none;
  height: 0.0625rem;
  background-color: var(--grey-200);
  margin: 1rem 0;
}

iframe {
  border: 0;
}

select {
  font-size: 1rem;
}

.body--bao {
  background-color: var(--blue-100);
}
