@use '../abstracts' as *;

.subjects-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include mq(medium) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .bc-button__button {
    text-transform: initial;
    min-height: 3.5rem;
    line-height: 1rem;
    position: relative;

    .pill {
      @include uppercase;
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
    }
  }

  .bc-button__button + .bc-button__button {
    margin-left: 0;
  }
}

.catalog {
  .panel--default {
    .step {
      margin-right: 1rem;
    }

    .selection {
      margin-left: auto;
      padding-right: 1rem;
      border-right: 0.0625rem solid var(--blue-300);
      display: flex;
      align-items: center;
      text-align: right;
    }

    .panel__heading + div > .panel__body {
      margin-top: 1.5rem;
    }
  }

  .methods,
  .modules {
    row-gap: 1rem;
  }
}

.body--bao {
  .subjects-grid {
    .bc-button__button {
      background: var(--blue-400);
      box-shadow: 0 0.125rem 0 0 var(--blue-500);
      font-size: 1rem;

      &:hover {
        background: var(--blue-500);
        box-shadow: 0 0.375rem 0.375rem -0.25rem var(--grey-500), 0 0.375rem 1rem 0 var(--grey-300);
      }
    }
  }

  .catalog {
    .panel--default {
      .selection {
        border-right-color: var(--blue-200);
      }

      .method-orb__title {
        color: var(--blue-600);
      }
    }
  }
}
