@use './abstracts' as *;
@use './layout/app' as app;

.body--bao {
  .bc-button {
    &__button {
      font-weight: 500;
      border-radius: 1.875rem;
      font-size: 0.875rem;
      line-height: 1.5rem;

      &:not(:disabled) {
        border-color: var(--blue-400);
        color: var(--blue-400);
      }

      &:hover {
        color: var(--white);
      }
    }

    &__primary:not(:disabled) {
      box-shadow: 0 0.125rem 0 0 var(--primary-500);
    }

    &__secondary:not(:disabled) {
      box-shadow: 0 0.125rem 0 0 var(--secondary-500);
    }

    &__danger:not(:disabled) {
      box-shadow: 0 0.125rem 0 0 var(--danger-500);
    }

    &__primary:not(.bc-button__outline),
    &__secondary:not(.bc-button__outline),
    &__danger:not(.bc-button__outline) {
      color: var(--white);
      border-color: transparent;
    }

    &__outline:not(:disabled) {
      background-color: var(--white);
      box-shadow: none;
    }
  }

  .bc-formfield__label {
    color: var(--blue-400);
    font-size: 0.85rem;
  }

  .bc-select {
    &__select {
      @include form-field-bao();

      &:focus {
        @include form-field-bao-focus();
      }
    }
  }

  .bc-formfield__textfield,
  .bc-formfield__textarea {
    @include form-field-bao();
  }

  .bc-formfield__textfield:focus,
  .bc-formfield__textarea:focus {
    @include form-field-bao-focus();
  }

  .bc-dialog {
    &__header,
    &__footer {
      background-color: var(--blue-100);
    }

    &__headline {
      color: var(--heading-color);
    }
  }
}

.bc-button {
  &__button {
    &:not(:disabled):hover {
      background-color: var(--secondary-500);
      border-color: var(--secondary-500);
      box-shadow: 0 0.375rem 0.375rem -0.25rem rgba(0, 0, 0, 0.25), 0 0.375rem 1rem 0 rgba(150, 150, 150, 0.15);
    }
  }

  &__primary {
    background-color: var(--primary-400);
    box-shadow: 0 0.125rem 0 0 var(--primary-500);

    &:not(:disabled):hover {
      background-color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }

  &__secondary {
    background-color: var(--secondary-400);
    box-shadow: 0 0.125rem 0 0 var(--secondary-500);

    &:not(:disabled):hover {
      background-color: var(--secondary-500);
      border-color: var(--secondary-500);
    }
  }

  &__danger {
    background-color: var(--danger-400);
    box-shadow: 0 0.125rem 0 0 var(--danger-500);

    &:not(:disabled):hover {
      background-color: var(--danger-500);
      border-color: var(--danger-500);
    }
  }
}

.bc-formfield__field {
  margin-bottom: 1rem;
}

.bc-formfield__label {
  font-size: 0.75rem;
  color: var(--body-color);
}

.bc-formfield__danger {
  color: var(--danger-400);
}

.bc-select {
  width: 100%;

  &__select {
    @include form-field();
    @include ellipsis-text;
    padding-inline-end: 1.5rem;

    &:focus {
      @include form-field-focus();
    }
  }
}

.bc-formfield__textfield {
  @include form-field();
}

.bc-formfield__textfield:focus {
  @include form-field-focus();
}

.bc-formfield__textarea {
  @include form-field();

  resize: vertical;
  min-height: 10rem;
}

.bc-formfield__textarea:focus {
  @include form-field-focus();
}

.bc-dialog {
  &__backdrop {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    align-items: flex-start;
    animation: fadein 0.5s;
    overflow: auto;
  }

  &__dialog {
    &:not(.bc-dialog__small):not(.bc-dialog__fullScreen) {
      max-width: min(calc(100% - 3rem), app.$containerWidth);
    }
  }

  &__headline {
    margin: 0;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: bold;
  }

  &__header,
  &__footer {
    background-color: var(--grey-200);
  }

  &__footer {
    .bc-button__button {
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding: 0.5rem 1.25rem;

      .bc-icon {
        font-size: 1rem;
      }
    }
  }

  &__body {
    p:last-child {
      margin-bottom: unset;
    }
  }
}

.bc-modal-dialog--open {
  overflow: hidden;

  .bc-dialog__dialog {
    animation: flyin 0.5s;
  }

  .bc-dialog__backdrop {
    display: none;

    &:last-child {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }
}

.bc-dialog {
  &__dialog {
    &.closing {
      animation: flyout 0.5s forwards;
    }
  }
}

.bc-modal-dialog--closing {
  .bc-dialog__backdrop:last-child {
    .bc-dialog {
      &__dialog {
        animation: flyout 0.5s forwards;
      }
    }
  }
}

.bc-checkbox {
  display: inline-flex;
}

.bc-tabs__tablist {
  .bc-tabs__tab {
    padding-block: 0.5rem;
    position: relative;

    &:hover,
    &.bc-tabs__selectedTab {
      color: var(--primary-400);
    }

    .bc-svg-icon {
      font-size: 1.25rem;
    }
  }
}

.bc-tabs__vertical {
  .bc-tabs__tab {
    + .bc-tabs__tab {
      border-top: 0.0625rem solid var(--grey-300);
    }

    &.bc-tabs__selectedTab {
      font-weight: bold;
    }
  }
}

.bc-tabs__horizontal {
  border-bottom: 0.0625rem solid var(--grey-300);

  .bc-tabs__tab {
    padding-inline: 1rem;

    &::after {
      display: block;
      width: 100%;
      content: '';
      border-bottom: 0.0625rem solid var(--primary-400);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out, border-bottom 100ms ease-in-out;
      position: absolute;
      left: 0;
      bottom: -0.0625rem;
    }

    &:disabled {
      border-bottom: none;
      color: var(--grey-700);
    }

    &.bc-tabs__selectedTab {
      &::after {
        border-bottom-width: 0.125rem;
        transform: scaleX(1);
      }
    }

    &:hover:not(:disabled) {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

#review-dialog,
#bc-exercise-dialog {
  .bc-dialog__backdrop {
    display: flex;
  }

  .bc-exercise-states__message {
    .heading {
      color: var(--white);
    }
  }

  .bc-exercise-score__container {
    margin-bottom: 1rem;
  }
}

.bc-editable-text-field__label {
  font-weight: bold;
}

.bc-searchbox__wrapper {
  .bc-searchbox__input {
    box-sizing: border-box;
  }
}

.bc-audience-manager {
  &__noGroupsTitle {
    @include heading-6;
    margin-block-end: 1.5rem;
  }
}

.bc-media-link-dialog-header__tutorialButton {
  color: var(--body-color);
}

.bc-searchbox__wrapper .bc-searchbox__input {
  background-color: var(--white);
}

.bc-date-range-input__button {
  align-items: center;
}

.bc-button__outline:not(:disabled):hover,
.btn--add-material:hover,
.bc-button__button.rc-upload:hover {
  color: var(--white);
}

.bc-icon {
  &.icon {
    width: 1rem;
  }
}

.bc-action-menu__menu {
  display: inline-flex;

  .szh-menu-button {
    display: inline-flex;
    padding: 0.375rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
    flex-shrink: 0;
  }

  .szh-menu__item {
    border: none;
  }
}

.bc-audience-manager__buttonWrapper {
  margin: 2rem -2rem -2rem;
  padding-inline: 2rem;
  min-height: 5.3125rem;
  background: var(--grey-200);
  align-items: center;

  .bc-button__button {
    font-size: 0.75rem;
    line-height: 1.5rem;
    padding: 0.5rem 1.25rem;
    border-color: var(--secondary-400);
    background-color: var(--secondary-400);
    box-shadow: 0 0.125rem 0 0 var(--secondary-500);
    color: var(--white);

    &:not(:disabled):hover {
      background-color: var(--secondary-500);
      border-color: var(--secondary-500);
    }

    &.bc-button__primary {
      border-color: var(--primary-400);
      background-color: var(--primary-400);
      box-shadow: 0 0.125rem 0 0 var(--primary-500);

      &:not(:disabled):hover {
        background-color: var(--primary-500);
        border-color: var(--primary-500);
      }
    }
  }
}

.bc-options-menu__menu .szh-menu {
  @include caption-1;
}
