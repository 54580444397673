@use '../abstracts' as *;

.orb {
  @include button-reset;
  text-align: center;
  padding: 0 0.5rem;
  position: relative;

  &__title {
    font-weight: bold;
    color: var(--blue-900);
    @include break-word();
  }

  &__subtitle {
    display: block;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    line-height: 1.125rem;
  }

  .pill {
    @include uppercase;
    position: absolute;
    right: 50%;
    top: 0;
    transform: translateX(3.125rem);
  }

  .thumbnail {
    box-shadow: 0 0.125rem 0.125rem 0 var(--grey-500);
    transition: box-shadow 0.15s ease;
  }

  &:not(:disabled):hover > .thumbnail {
    box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);
  }

  &__pill-wrapper {
    margin-top: 0.75rem;
    transition: transform 0.25s ease;
    text-align: center;
    overflow: hidden;
  }
}

.body--bao {
  .orb__title,
  .orb__subtitle {
    color: var(--blue-600);
  }

  .orb__pill {
    background-color: var(--secondary-400);
  }
}
