@use '../../abstracts/breakpoints' as *;

.student-assignment--filter {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.student-assignments {
  .bc-tabs__tablist,
  .bc-tabs__tabpanel {
    padding: 0;
  }

  .bc-tabs__tablist {
    margin-bottom: 2rem;
  }

  .bc-tabs__tab {
    .pill {
      margin-right: 0.5rem;
      position: relative;
    }
  }

  .table-assignments {
    table-layout: fixed;
    color: var(--primary-text-color);
    width: 100%;

    .table__tr {
      min-height: 3.5rem;
    }

    .table__td {
      padding: 0.5rem 0.75rem;

      &--icon {
        width: 1rem;
        padding-inline-end: 2rem;
        font-size: 1.25rem;

        img {
          width: 1.25rem;
          max-width: 1.25rem;
          height: 1.25rem;
        }

        .bc-svg-icon {
          font-size: 1.5rem;
          display: flex;
        }
      }
    }

    .assignment__due-date {
      min-width: 10rem;
      width: 10rem;
    }

    .assignment__exercise-score {
      min-width: 7.5rem;
      width: 7.5rem;
    }

    .assignment__action {
      width: 12rem;
      display: none;

      .flex {
        display: flex;

        .btn {
          flex-grow: 1;
        }
      }

      @media (min-width: map-get($breakpoints, 'xlarge')) {
        display: table-cell;
        gap: 0.5rem;
      }
    }

    .btn {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
    }

    .table-actions {
      &-trigger {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover svg {
          fill: var(--primary-400);
        }
      }

      &-panel {
        position: absolute;
        z-index: 100;
        bottom: -1rem;
        right: -1rem;
        transform: translateY(100%) translateX(2rem);
        background-color: var(--white);
        border: 0.0625rem solid var(--material-sidebar-bg-color);
        box-shadow: 0rem 0.125rem 0.375rem rgba(150, 150, 150, 10%);
        color: var(--blue-500);
        border-radius: 0.5rem;
        padding: 0;
        flex-direction: column;

        &:after {
          border: 0.0625rem solid var(--material-sidebar-bg-color);
          background-color: var(--white);
          box-sizing: border-box;
          content: '';
          height: 1.25rem;
          position: absolute;
          transform: rotate(-45deg);
          width: 1.25rem;
          z-index: -1;
          top: -0.625rem;
          right: 1.5rem;
        }
      }

      &-list {
        padding: 0.5rem 1rem;
        background-color: var(--white);
        margin: 0;
        list-style-type: none;
        border-radius: 0.5rem;
      }

      &-item {
        &:last-child {
          border: none;
        }

        button {
          color: var(--blue-700);
        }

        button:disabled,
        button:disabled:hover {
          color: var(--grey-700);
        }

        &:hover {
          button {
            color: var(--primary-400);
          }

          .icon {
            fill: var(--primary-400);
          }
        }
      }

      &-action {
        display: flex;
        width: 100%;
        align-items: center;
        box-shadow: none;
        background-color: transparent;
        border: none;
        padding: 0.25rem;
        font-size: 1rem;
      }
    }
  }
}
