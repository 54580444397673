.splide__arrows {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}

.splide__arrow {
  background: unset;
  border: unset;
  padding: unset;
  display: flex;
  opacity: 0.7;
  position: absolute;
  transform: translateY(-50%);
  text-indent: -9999999px;
  width: 2rem;
  height: 4rem;
  content: '';

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    background-image: none;
    cursor: auto;
  }

  &--prev {
    left: 1em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAABACAYAAAB7jnWuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABE9JREFUeNrsWd9LU1Ec927TbJrOWSkW5tQMzNIVloJhqx5SEKQfj/YLejIEX3yJEUgRsRdxiH+CFj4MRMQHEUGyJO0haC17kSAMceg2t+V+3L7HvnedXe+2c+92k2AHPtyxe875fO653+/3fM/3cjzP5xxk0+QccMsKyArICtCpMSkHTfwfnyDg6FQi5iTuSQrRqUCswSstgpBGyVUshEs3FCM5R5FrEcJ/AjlBhPq9J0KXIXKBmMyXOzc316TX60vcbreno6PjExKHEGEcHhWWQhEo4lxAPqAIiK+Gw+HvPNV8Pt/7np6eBrhfBjAADuOYP68qA+RkwuKZmZlrkUjEw0s0v9//BfqYUEQRCtYqEiBFPj09fT0RudAmJibuQ99KQClAH1uFf0FO2vLysg361+AqFALyyFwaBQYnGFteb29vmcVisWs0miOpxs/Ozq5IuqjSJwfyumAw+JlnaJubmx9hjBlQK14B1cm3t7e/tbW13YBx5wBVgKOAAiYboMh1aLmyyEOhkK+1tfUmjLsAqANUoCvm45ycHPKivr6+03LIrVbrIxjXDKhHD4h/+kQCKEPR4rsq7O/vr1VIfhaX/jjGgENCDNjjSiJAi0qJYuPW1tYbheRCACqmll6TUIDI6EjAKFFI3oDk5UgeF4JjfCks3gBW/Fot8jgBUu7GSk6aEvKYACl3k0M+NTX1Ugm5WIAWLbRoY2PD9i/IhWSIXvoCl8t1Tyb5JSSvlktOC9AKSx8IBN6ykE9OTr6C/i2A87jLlUslHAyh/q/LjY2NmVnIHQ4H2VqvYIg9AzhB3DVun2cgJ6C3Yw7SKY5xa+Yxz4tQeV6ESjpln46EiHdsZ2fnHcsqQAr2HPo34rsvE4VZ5hXIEUW90qWlpcesRjg/P/8s3VewzwjJlul0Ol+wilhYWLCma4Sc6DWQLbNyfX3dwSpicXHxqWI3lApEuHVWyRExPj7+UFEgShSK0bBMrCLAg7zDw8N3FIXiRJsRTlStpoiUCajaIliyYANOVEOOWJkWwZKK69HHK7q7uy/CYdPFKsJut9+WnZIlywsBJ7u6uprliIDjeYvspDRRZkxCNeBUZ2fnZa/X+5VFBOlH+stKy5OdDfBJTOTJWEVAUvtB9sEk2elIiBFyRAwNDd2VOpqlPB1jMYmnajy7gCAgAEfzn4ODg0/geO5LNY/ZbK7Hh6DrR2yFSkpEBPd+IiJAYLPZVkdHRx+kErG2tvaDIpZ3PGcIVCbicsTqpZbf4/GsQp8mqQJFRko0OGnVyMjILbGLut3ulfb2dgt1QI0r0SiqE0pVS9C/iZHmg8E1Go1GA4Rv38DAgJN6ZX687mIqF1VcqBSJEGIFDR3ejyLZLwpCDqm8UEmWF8uuETRQsZFqKQHiQmUUp0ivUkqJiIpclalUm5FasdxiNSJzxWrWcr2YWJXvBbGn2v+9IuEHi4yuQPajVVbAfyngtwADAIWyoD35P6LfAAAAAElFTkSuQmCC');
  }

  &--next {
    right: 1em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAABACAYAAAB7jnWuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABClJREFUeNrsmU1ME1EQx7vd8mHR8iEfRg0IhGqAYPCr7UkTD7Y9YEQvmGCCVxI8lYOmmBBOHiE9cSbp0Y/EHgmESOqNxBiqNnAoloQLtBUq23adh7Pmsexu33YXONiXTCjtzv5/+3Zmdt5bThRFy2kOq+WURxmgDFAGsKn9wMGQfyceQ9GwaQhzCr+ZDmJTEebw9tAQIlrBTBCbTNyKxlOfOUo8j1Yg/xM3oxA2WUBa8buKxcXFfrvdXr+1tZX2+XxfUDgHJuDfvDQbRiGk6a4AOzM1NdW2t7f3SaSGIAiJubk5P/x+EawRzAFWjT4Hs0QYSjEJgMcTOuTi0sjlcunZ2dmHcEwrWDNYLQE2CmGhrt4O035X1BgEIhQKDcKxV8BazICQACrBzkaj0UdikUEgZmZmHsPx7WAXjELQAOcikch9kWGYCXFoBsi0ZjKZKAtENpv96fV6PUYh6BioIRE+NDTUt7u7u8oCkU6nvxmFkLLAhllQR1JtYGDgFsxE7CQg5HWgBvO81e/3u8jJWSGgWLkRQsqOarwwTQiJQqoFVVhkSJ5fIYFGAq4EiGaqWGlC0ABW6lbU4pW064UgMwd+bWBNGNiVeHGcEgR9Lw6VZIQg97R9enr6CStEIpH4AD7XwC6DNeBtrZAgVAHMhNjY2HgPPl347KgnVVYtKJWi0hSIZDL5Dnw6i2WGWmqYArG5ufkWfDq0MkMrP1UhlpeXX4mMAyHozKii46FYlVKD6NADAQ+5l/gYb6SC8m+3xVAq5RB1CNG5tLQUZAEgVRWOd2JQSreCZwJQgLBjZF8Cu7qwsPCaBSIcDo9gfThPZ4XehUmBakwP+kOe5wssjvl8nm52OXqxoWcG6HLdsrKyEmC5+lQq9R2Ov44Z0UTHga2EpRyB4GOx2FOn0/mGxQmCMCxv6ZlnQCkI4/H4C9YMWF9f/wg+N0oKQqU0hPQbZBVfW1uLgM9tsG7daagkTnpGCKaUTvEe7KLZC5FJ4nfAeos1Kccl7kLxDl0PI6PiOzs7P8CHdEV91JOwTqtHNFXc4/E8AL9+1l5AsyUbHR116hT3gt9NPd2QalM6NjbWBQuPryzi29vbcbfb7aPSjbkfVGzLA4FApx5xl8vlp9JNV0esuDBRW54ziutaExxZms3Pz99jERcEIRMMBp+jeK/RpZmu5bmZ4rqX5zLxnhNdnhPxiYmJETPFj2zRkHZpeHi4FxqIz7Q4BGZycnLyGT5Wu83couFkaViJJ7SHQqF+h8PRAAuMX+Pj46vYgv0Gy6KRz/vUlp1YynYdRxmPqVNFmXRlIgrtyyxvRPxgo5I44kZpAUXozzwFoLhRaURcvlMqdbc56oo1t2qNilv+NQU6N6ulptKMzWpOfg6t7XozhVXfF0gnV3hfcSwvLLjym9MywH8P8EeAAQC6ZAVxKOgMPwAAAABJRU5ErkJggg==');
  }
}
