@use '../abstracts' as *;
@use '../base/helpers' as *;

.my-account {
  .bc-tabs__tab {
    display: flex;
    justify-content: space-between;
  }

  .smartschool-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .smartschool-list__name {
    font-weight: 700;
  }

  .school-selector {
    display: flex;
    justify-content: space-between;

    .static-field {
      width: 85%;
    }

    .bc-button__button {
      margin-left: 1rem;
    }
  }

  .message-box {
    margin-top: 1rem;
    width: auto;
    display: inline-flex;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .image-selector {
    margin: 0 auto;
    border: 0.0625rem solid var(--blue-400);
    background-size: cover;
    background-position: 50% center;
    display: flex;
    cursor: pointer;

    &__icon {
      transition: all 0.25s ease;
      background-color: var(--white);
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--secondary-500);
      box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-900);
    }

    &:hover {
      .image-selector__icon {
        color: var(--primary-400);
      }
    }

    &.bg-selector {
      height: 3.125rem;
      justify-content: center;
      align-items: center;
      background-color: var(--green-400);
    }

    &.avatar-selector {
      @include image-circled(7rem);
      .image-selector__icon {
        position: absolute;
      }
    }
  }

  .identity {
    .identity__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .identity__column {
      display: flex;
      align-items: center;
      gap: 1rem;

      &--action {
        @include mq(medium, down) {
          width: 100%;
        }
      }
    }

    .identity__logo {
      height: 5rem;
      width: 5rem;
      margin: 0 auto;
      flex-shrink: 0;
    }

    .identity__full-name {
      margin-bottom: 0.5rem;
    }

    .identity__email {
      margin-bottom: 0;
    }

    .identity__incomplete {
      display: flex;
      align-items: center;
      margin-block: 0.5rem 0;
      gap: 0.5rem;
    }

    .identity__incomplete-link {
      @include button-reset;
      text-decoration: underline;

      &:hover {
        color: var(--primary-400);
      }
    }

    .identity__button {
      min-width: 9rem;
      gap: 0.5rem;

      @include mq(medium, down) {
        width: 100%;
      }
    }
  }

  .teacher-profile {
    &__section {
      margin-bottom: 2rem;
    }

    .list__item {
      padding-block: 0.5rem;
      border-bottom: 0.0625rem solid var(--grey-300);
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        background: var(--grey-100);
      }

      .smartschool-settings {
        margin-left: auto;
      }

      .item__name {
        flex-grow: 1;
      }
    }
  }

  .licenses {
    &__actions {
      background-color: var(--grey-200);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      padding: 1rem;
      margin-bottom: 2rem;

      @include mq(medium) {
        flex-direction: row;
      }
    }

    .license__filters {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      width: 100%;
      order: 1;

      @include mq(medium) {
        flex: 0 1 75%;
        flex-direction: row;
      }
    }

    &__no-results {
      border-top: 0.0625rem solid var(--grey-300);
      border-bottom: 0.0625rem solid var(--grey-300);
      padding: 1rem;
      text-align: center;
    }

    .activate-license-btn {
      white-space: nowrap;
      width: 100%;

      @include mq(medium) {
        width: auto;
      }
    }

    .license {
      border-top: 0.0625rem solid var(--grey-300);
      padding: 1rem;

      .heading-6 {
        line-height: 1.5rem;
      }

      &__subject {
        color: var(--secondary-400);
      }

      &__item {
        font-size: 0.9rem;
      }

      &__transfer-warning {
        color: var(--warning-400);
      }

      &__actions {
        display: flex;
        margin-top: 1rem;
        flex-direction: column;
        gap: 1rem;

        @include mq(medium) {
          margin-top: 0;
          align-items: flex-end;
          justify-content: flex-start;
        }

        .bc-button__button {
          + .bc-button__button {
            margin-left: 0;
          }
        }
      }
    }
  }
}

#select-school-modal {
  .bc-dialog__body {
    padding: 0;
  }

  .header {
    display: flex;
    padding: 4rem 2rem;

    &--gradient {
      background: linear-gradient(180deg, var(--header-bg-color) 0%, var(--primary-400) 100%);
    }
  }

  .select-school__form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 2rem;

    label {
      color: var(--white);
    }

    .select-school__field {
      margin-bottom: 0;
    }

    .select-school__field--zipcode,
    .select-school__separator,
    .select-school__submit {
      flex-grow: 0;
    }

    .select-school__field--name {
      flex-grow: 1;
    }

    .select-school__field--zipcode {
      .bc-formfield__textfield {
        width: 6.25rem;
      }
    }

    .select-school__separator {
      padding-block: 0.75rem;
    }
  }

  .select-school__results {
    padding: 2rem;
    max-height: 32rem;
    overflow: auto;
  }

  .select-school__table {
    width: 100%;

    tbody {
      overflow: scroll;
    }
  }

  .select-school__table-row {
    font-size: 0.85rem;
    cursor: pointer;
  }

  .select-school__table-row--message {
    cursor: auto;
  }

  .select-school__table-row--selected {
    td:first-child {
      position: relative;

      &:before {
        content: '';
        width: 0.25rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--blue-900);
      }
    }
  }
}

#select-background-modal,
#select-avatar-modal {
  .btn-custom-image {
    margin-right: auto;
  }

  .bc-dialog__header {
    min-height: 85px;
    height: auto;

    .bc-dialog__container {
      padding-block: 1rem;
    }
  }

  .splide__slide {
    .user-selection {
      position: relative;

      &::before {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABOlJREFUeNrMWEtsG1UUfTP+Jy61CTRdJJIVRJpNidtskoDAIi0SIovQD1IR/bBLJRZtcUpBlQgSoFaYzwKaiA0JCFBLaVgAEqWuoqqQLJrKCSzS0qSVmkVTUQgiju3YHnPPdN7gGnsyM57YHOlJM9Ykc+a+e8+57wq5XI5ZhICyOEat+sd2k3/no9Xz93K8W2BCm9dZEyj14F+pxUmnzTHmsbt+oNtvzLxMMBjJIBE7usZZux03P89Nspk/59jU7atscXlJvga8Tg97yN/Iah0e1lrfzFrXNbMmfwNLpJN3PA73h/TIB7QWrCYZWEonP65xuLfOEpEzV84TwRgRS+j+uvraOvZUUwfb3tKVs4u2ZYruMfq53yqS+5az6UHaNsdHl06KiF45QJSf3dDFdrR0SfTm6/ThO+jnmGmSqWz6C5fNsWuEIvfZL98aipyeyL7xeK+cBoQXaQ0ZJemLpxPnRCZsOj42VHb0tNDXvpdtbWrXJFqUJBXHaZtg2/byuXcFXgyriW0tT7LezTtLEhULf0hkUgOo3koRBM5Mn2c/zo7j8hNaoZVIhkjPeiPjn7JKEeR4Z3xYlrSMlP1O0eGiJH3JTOoUHjw7O8aqgQgRJSXxUMGeKEXyAOVn3cDEV6xagHqcmDglQFHyLZaT9GWkzOHT01FxPn6HVRPYxfn4HyjeSCHJHrto94xcibL/A6DJivUGVJKUi6+iuqwU60ItxILb6I0m+byE4HGSPrfd1fzTXGxVCMKvIdZYka5D1Hg06Pq7izdjIhnKC5xkiHc0VgOE9rftVO9hgZEth2RLXAkIGnVRbZxkcHYVNBFbiy1Gu1Yo3HqKM0+ngyJ1N08YFe7OhtYV82v/5ud486Bi6vZvclHoQd6H+BBJ/y0DsoMmtp+6l4Gnj5bML56H+aD8Yv0XBgwFQ9nhkGi0vQLBu9f3y/mFqGrloWp7Y8OG1WMxnSjeYGgB/V9+juEapHdv7NbMwxG5kzdfmIYOYmgCEL1CErs3PiNHME6RKsxDbJlZq/Uq7xFFQbimV7tQYL3fv8WKqQG2vVgevn5h0HQElQ8eFcl+fl2vQ7fyqy4cfY/3f9qRpzw02wvkaekCcnIUjPVaFu9WsPWDl0tvY7l5CBVREBP5Sa2zIWiqo+6Lvi9vq1V5qJKkszpp+Civ7gXcFEqJXkzOX70nT8vNQ64SjzUGpbUu77AqQbgByXoDuVkqT8vJw3+LMMg8DrfIxzLqaZFao9+p86hDrlUbn/e8Ld3n8p6kDv35e8ScmIchIWajaRVgDA/W+EUi+FqxM87QUjo509ext2oEESCMXyivj9PtjaJHWsxlHln3sGpzlQZsF/MhcrQjWuduyNFB2Bw6mUoCnt+4dn1SGWBpTzAwO6Rz75fh9j0VI8rnQQ7RvqvYhK3kVI1P1OAqEO3VAPQwTAQVjTY2sMprEI5RfrwCe8N0wcrTJGzvcMc+ye9ek6LjdKfWjFLPEDWE8YuUyz3w9XRUwNm8HLKo4D1UmNheCsIEBWELW2E0rXccjXnlEcqZAxkp6wTZu5MG/c6CLX2UnATklNl5mGkMTs2QVMliZkQveYleUodxyBR598zCzf9M4dAYY7iPXnVT/QYJNgcdpup9Uy85syTzgbaphyLcLTIhANKFD4BUNpeNUc96UfHhG2Ze9I8AAwD+jkuA9ySRvwAAAABJRU5ErkJggg==')
          top left / cover;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

#select-background-modal {
  .bc-dialog__body {
    padding: 0;
  }
}

#select-avatar-modal {
  .avatar {
    @include image-circled(10rem);
    margin: 0 auto;
    border: 0.0625rem solid var(--blue-400);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

#select-custom-background-modal,
#select-custom-avatar-modal {
  .file-upload {
    border: 0.0625rem dashed var(--blue-900);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.1875rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 1rem;

    input[type='file'] {
      cursor: pointer;
      width: 100%;
      height: 100%;
      text-indent: -99999px;
      font-size: 0; /* Safari does not hide the text next to the button */
    }
  }

  .btn-file-upload {
    gap: 0.5rem;
  }

  .flex-container {
    display: flex;
    justify-content: center;
  }
}

#select-custom-background-modal {
  .file-upload {
    height: 12rem;
  }
}

#select-custom-avatar-modal {
  .file-upload {
    height: 20rem;
  }

  input[type='range'] {
    min-width: 12rem;
  }
}

#select-subject-modal {
  .bc-button__button {
    &.selected-subject {
      background-color: var(--primary-400);
      border-color: var(--primary-400);
      color: var(--white);
      box-shadow: 0 0.375rem 0.375rem -0.25rem rgb(0 0 0 / 25%), 0 0.375rem 1rem 0 hsl(0deg 0% 59% / 15%);
      transform: translateY(-0.0625rem);
    }
  }

  .subjects-wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @include mq(medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(large) {
      grid-template-columns: repeat(3, 1fr);
    }

    .bc-button__button {
      overflow: hidden;
      position: relative;

      span {
        @include ellipsis-text;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }

      + .bc-button__button {
        margin: 0;
      }
    }
  }
}
