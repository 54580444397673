@use '../abstracts' as *;

.material-dialog {
  .dialog-subtitle {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;

    &__part {
      display: flex;
      align-items: center;
      max-width: 100%;
      gap: 0.5rem;

      span {
        @include ellipsis-text;
      }

      .bc-svg-icon {
        font-size: 1.25rem;
      }
    }
  }

  .dialog-info {
    @include button-reset;
    width: 1.5rem;
    height: 1.5rem;

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    @include heading-6;
    color: var(--primary-text-color);
    margin-bottom: 1rem;
  }

  .preview-icon-wrapper {
    display: flex;

    @media (min-width: 576px) {
      justify-content: center;
    }

    .preview-icon {
      font-size: 3rem;
      width: 3rem;
      height: 3rem;

      // When all the icons are SVGs, the following styles can be moved to the preview-icon class
      .bc-svg-icon {
        font-size: 4rem;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .bc-button__button + .bc-button__button {
      margin-left: 0;
    }
  }
}

#image-preview-modal,
#pdf-preview-modal {
  .bc-dialog__body {
    display: grid;
    grid-template-rows: auto 1rem 1fr;
    overflow: auto;
  }

  @include mq('large') {
    .bc-dialog__body {
      grid-template-columns: 30% 1rem 1fr;
      grid-template-rows: auto;
    }
  }
}

#image-preview-modal {
  .image {
    grid-row-start: 3;

    img {
      margin: 0 auto;
    }
  }

  @include mq('large') {
    .image {
      display: flex;
      align-items: center;
      grid-row-start: 1;
      grid-column-start: 3;

      img {
        /* 100vh - padding body - header height - modal margin */
        max-height: calc(100vh - 4rem - 85px - 3rem);
      }
    }
  }
}

#pdf-preview-modal {
  .pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row-start: 3;
    width: 100%;
    height: 100%;
    min-height: 40rem;

    @include mq('large') {
      grid-row-start: 1;
      grid-column-start: 3;
    }
  }
}

#weblink-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: calc(100% - 3rem);

  &:not(.bc-dialog__fullScreen) {
    height: 100%;
  }

  .bc-dialog__body {
    padding: 0;
    position: relative;
    flex-grow: 1;
  }

  iframe {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#mini-dialog-modal {
  .bc-mini-dialog__headerTitle,
  .bc-mini-dialog__actor,
  .bc-mini-dialog__optionName,
  .bc-mini-dialog__optionsHeader {
    font-weight: bold;
  }
}

.embeddable-media-modal {
  .bc-dialog__body {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 56.25% 0;
  }

  iframe {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#geo-gebra-modal {
  width: auto;
  max-width: calc(100% - 3rem);

  .bc-dialog__body {
    padding: 0;
    display: flex;
  }
}

#h5p-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .bc-dialog__body {
    padding: 0;
    height: 0;
  }

  &.custom-dimensions {
    max-width: calc(100% - 3rem);
  }
}

#app-modal {
  .bc-dialog__body {
    padding: 0;
    position: relative;
    flex-grow: 1;
  }

  iframe {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
