@use '../abstracts' as *;

$color-blue--login-button: rgb(71, 173, 200);

.login-content {
  .panel {
    padding: 0;
  }

  .columns {
    min-height: 37.5rem;
    display: flex;
    align-items: stretch;
    position: relative;

    @include mq(medium, down) {
      flex-wrap: wrap;
    }

    .bc-button__button + .bc-button__button {
      margin-left: 0;
    }
  }

  .column {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .column--left {
    margin-right: auto;
    flex-basis: 55%;
  }

  .column--right {
    flex-basis: 40%;
    background-color: var(--grey-200);
    position: relative;

    @media only screen and (min-width: 769px) {
      @include pseudo() {
        left: -3.125rem;
        border-top: 18.75rem solid transparent;
        border-right: 3.125rem solid var(--grey-200);
        border-bottom: 18.75rem solid transparent;
        border-left: 0 solid transparent;
      }
    }
  }

  @include mq(medium, down) {
    .column {
      flex-basis: 100%;
    }
  }

  .headline {
    @include heading-2;
  }

  .info-links {
    padding-top: 1rem;

    p {
      margin: unset;
      margin-bottom: 0.75rem;
      @include caption-1;
    }
  }

  a.info-link {
    color: var(--secondary-400);
    border-bottom: unset;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--primary-400);
    }

    .icon {
      margin-right: 0.25rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .features {
    .features__title {
      margin-top: 0;
      margin-bottom: 1.5rem;
      color: var(--secondary-400);
      @include heading-4;
    }

    .features__list {
      padding: 0;
      margin: 0;

      .icon {
        margin-right: 1rem;
        width: 1.75rem;
        height: 1.75rem;
        flex-shrink: 0;
      }

      &.list {
        .list__item {
          display: flex;
          align-items: center;
          color: var(--blue-900);
          list-style: none;
          @include caption-1;
        }

        .list__item + .list__item {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .button--login {
    margin-bottom: 1rem;
    padding-left: 4.5rem;
    position: relative;
    width: 100%;
    border: none;

    .logo {
      width: 4rem;
      background-color: $color-blue--login-button;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      box-shadow: 0 0.125rem 0 0 darken($color-blue--login-button, 10%);
      transition: background 0.15s ease, box-shadow 0.15s ease;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &:hover {
      .logo {
        background-color: darken($color-blue--login-button, 10%);
        box-shadow: none;
      }
    }

    &:disabled {
      .logo {
        background-color: inherit;
        box-shadow: inherit;
      }
    }
  }
}

.body--bao {
  .login-content {
    .column--left {
      flex-basis: 45%;
    }

    .column--right {
      flex-basis: 47.5%;
      background-color: var(--blue-200);

      &::before {
        border-right-color: var(--blue-200);
      }
    }

    @include mq(medium, down) {
      .column {
        flex-basis: 100%;
      }
    }

    .features {
      padding: 1.5rem;

      @include mq(large, down) {
        padding: 0.75rem;
      }

      @include mq(medium, down) {
        padding: 0;
      }

      .features__title {
        @include heading-3;
        color: var(--heading-color);
      }

      p {
        @include paragraph;
      }
    }

    .button--login {
      .logo {
        padding-left: 0.5rem;
      }
    }

    a.button--student-platform {
      border-bottom: 0;

      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}

.body--vo {
  .login-content {
    .column--left {
      flex-basis: 45%;
    }

    .column--right {
      flex-basis: 47.5%;
    }

    @include mq(medium, down) {
      .column {
        flex-basis: 100%;
      }
    }

    .features {
      padding: 1.5rem;

      @include mq(large, down) {
        padding: 0.75rem;
      }

      @include mq(medium, down) {
        padding: 0;
      }

      .features__title {
        @include heading-3;
        color: var(--heading-color);
      }

      p {
        @include paragraph;
      }

      a {
        margin-block-start: 1.5rem;
      }
    }
  }
}
