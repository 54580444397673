@use '../../abstracts/' as *;

.search-results {
  .notification-banner {
    margin-bottom: 1rem;
  }

  .search-result {
    margin-bottom: 1rem;

    > .bc-accordion__headline {
      background-color: var(--sidebar-bg-color);
      color: var(--white);
      padding: 0.5rem 1rem;
      margin: 0;

      .bc-accordion__title {
        padding-right: 1rem;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;

      .caption-2 {
        font-weight: normal;
        align-items: center;
      }

      .pill {
        margin-right: 0.5rem;
      }
    }

    &__header-section {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__title {
      display: flex;
      flex-direction: column;
    }

    &__cover {
      display: flex;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 2.5rem;
      width: 1.875rem;
      border: 0.0625rem solid;
    }

    &__link {
      border: none;
      display: flex;
    }

    .bc-accordion__panel {
      padding: 1rem;
      border: 0.0625rem solid var(--grey-200);
    }
  }
}
