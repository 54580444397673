.score-debug {
  position: absolute;
  display: none;
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.1875rem;
  left: 50%;
  bottom: 0;
  transform: translateY(-15%) translateX(-50%);
  box-shadow: 0 0 0 0.0625rem var(--grey-200), 0 0.1875rem 0.375rem 0 var(--grey-300);

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.625rem 0.625rem 0 0.625rem;
    border-color: var(--white) transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  pre {
    width: 12.5rem;
    line-height: 1;
    font-size: 0.875rem;
  }
}
