@use '../abstracts' as *;
@use '../base/helpers' as *;

.my-teachers {
  .school-year-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include mq(medium) {
      flex-wrap: nowrap;

      .bc-select {
        max-width: 24rem;
      }
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--heading-color);
    margin: 1.5rem 0;

    .heading-6 {
      margin-bottom: 0;
    }
  }

  .panel + .my-teachers__heading {
    margin-top: 1.5rem;
  }

  .invite-panel {
    box-shadow: 0.25rem 0 0 var(--warning-400) inset;
  }

  .teacher-panel,
  .invite-panel {
    .action {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      @include mq(medium) {
        margin-top: 0;
      }
    }

    .action__button {
      width: 100%;

      &--danger:not(:disabled):hover {
        background-color: var(--danger-400);
        border-color: var(--danger-500);
      }
    }
  }
}

.student-group {
  .student-group__teacher {
    color: var(--heading-color);
  }

  .student-group__info {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
    }
  }

  .student-group__smartschool-icon {
    height: 1rem;
    width: 1rem;
    display: inline-block;
    margin-left: 0.5rem;
  }
}
