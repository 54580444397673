@use '../../abstracts' as *;

.view-mode-selector {
  .entrance-links {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 9.5rem);
  }

  .entrance-link {
    position: relative;
    transition: box-shadow 0.15s ease;
    border-bottom: none;
    background-color: var(--blue-900);
    border-radius: 0.1875rem;
    padding: 1.5rem;
    text-align: center;

    .body--bao & {
      background-color: var(--blue-400);
    }

    &,
    &:hover {
      color: var(--white);
    }

    &:hover {
      background-color: var(--primary-400);
      box-shadow: 0 0.1875rem 0.125rem 0 var(--grey-600), 0 0.125rem 1rem 0 var(--grey-700);

      .entrance-link__icon {
        color: currentColor;
      }
    }

    &__icon {
      margin-bottom: 0.75rem;
      color: var(--primary-400);

      .body--bao & {
        color: var(--white);
      }
    }

    .pill {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.digibook-apps {
  --repeat: auto-fit;

  @include mq(large) {
    --repeat: 4;
  }

  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-columns: repeat(var(--repeat, auto-fit), minmax(10rem, 1fr));
  gap: 1.5rem 1rem;
}
