@use '../abstracts' as *;

.accordion {
  &--material {
    .bc-accordion__headline {
      border-block: 0.0625rem solid var(--blue-200);
      background-color: var(--grey-100);
      padding: 0.75rem 0.75rem 0.75rem 2.75rem;
      margin: 0;
    }

    .bc-accordion__title {
      @include caption-2;
      min-height: 1rem;
      text-transform: uppercase;
      line-height: 1.25rem;
    }
  }

  &--selectable {
    .bc-accordion__headline {
      padding: 0.75rem 0.75rem 0.75rem 0;

      .bc-label__label {
        margin-right: 0.75rem;
      }
    }
  }
}
