@use '../../../abstracts' as *;

$wordlist-nav-hover: hsl(192, 27%, 65%);
$wordlist-language-selector: hsl(196, 23%, 91%);

$wordlist-heading--level-0: hsl(194, 25%, 80%);
$wordlist-heading--level-base: hsl(206, 22%, 81%);

.wordlist {
  --language-selector-height: 2.75rem;

  > .bc-dialog__body {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .bc-tabs__tablist {
    display: flex;
    flex-direction: column;
    background-color: var(--sidebar-bg-color);
    margin-bottom: 0;

    .bc-tabs__tab {
      @include button-reset;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.625rem;
      background-color: var(--body-color);

      .icon {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--white);
      }

      & + .bc-tabs__tab {
        border: none;
      }

      &.bc-tabs__selectedTab {
        background-color: var(--white);

        .icon {
          fill: var(--primary-400);
        }
      }

      &:not(&.bc-tabs__selectedTab):hover {
        background-color: $wordlist-nav-hover;
      }
    }
  }

  &__container {
    display: flex;

    .row {
      flex-grow: 1;
      overflow: auto;

      @media (min-width: 992px) {
        flex-wrap: nowrap;
      }
    }
  }

  &__column {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &__content {
    box-shadow: 0.125rem 0 0.375rem 0 rgba(0, 0, 0, 0.15);
  }

  &__preview-wrapper {
    justify-content: center;
  }

  &__preview {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 1rem;
  }

  &__nav-btn {
    @include button-reset;
    border: 0.0625rem solid rgba(0, 77, 98, 0.2);
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.1875rem;
    flex-shrink: 0;

    &[disabled] {
      opacity: 0.25;
    }
  }

  &__language-selector {
    background-color: $wordlist-language-selector;
    height: var(--language-selector-height);
    display: flex;
    align-items: center;
    justify-content: center;

    .language-selector__language {
      color: var(--heading-color);
      display: flex;
      flex-basis: calc(50% - 2rem);

      &:first-child {
        justify-content: flex-end;
      }
    }

    .language-selector__button {
      @include button-reset;
      border: 0.0625rem solid rgba(0, 77, 98, 0.2);
      width: 2rem;
      height: 2rem;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
      transition: color 0.15s ease;
      margin: 0 2rem;

      &:hover {
        color: var(--heading-color);
      }

      &[disabled] {
        opacity: 0.25;
      }
    }
  }
  &__list {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__tab {
    min-height: 16.5rem;
    max-height: calc(50vh - var(--language-selector-height));
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;

    &.bc-tabs__tabpanel {
      display: flex;
    }

    @media (min-width: 992px) {
      max-height: calc(100% - var(--language-selector-height));
    }
  }

  &__header {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    color: var(--heading-color);

    &--level-0 {
      background-color: $wordlist-heading--level-0;
    }

    &--level-1 {
      background-color: rgba($wordlist-heading--level-base, 0.65);
    }
    &--level-2 {
      background-color: rgba($wordlist-heading--level-base, 0.4);
    }
    &--level-3 {
      background-color: rgba($wordlist-heading--level-base, 0.15);
    }
  }

  &__header-btn {
    @include button-reset;
    height: 1.875rem;
    width: 1.875rem;
    background-color: var(--white);
    border-radius: 0.1875rem;
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--body-color);

    &:hover {
      color: var(--heading-color);
    }
  }

  &__badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    min-width: 2rem;
    line-height: 0.75rem;
    background-color: var(--white);
  }

  &__table {
    width: 100%;
    word-break: break-word;
  }

  &__table-row {
    border-bottom: 0.0625rem solid rgba(26, 122, 148, 0.15);
    cursor: pointer;
    height: 2.625rem;

    &:hover {
      background-color: var(--grey-100);
    }

    &--active {
      .wordlist__table-cell:first-child {
        box-shadow: inset 0.1857rem 0 0 0 var(--primary-400);
      }
    }
  }

  &__table-cell {
    color: var(--heading-color);
    padding: 0.5rem 0.75rem;

    &:first-child {
      width: 50%;
    }

    &--bold {
      font-weight: bold;
    }

    &--action {
      width: 2.75rem;
      padding: 0 0.75rem 0 0;
    }
  }

  &__btn-favorite {
    @include button-reset;
    width: 100%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease;
    &:hover {
      color: var(--primary-400);
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &__actions {
    position: relative;
    margin-top: 1rem;
  }

  &__no-favorites {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;

    img {
      width: 3.75rem;
    }
  }

  &__search-input {
    position: relative;
  }

  &__search-adornment {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
  }

  &__search-clear {
    @include button-reset;
    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__search-error {
    margin: 1rem 0 0;
    text-align: center;
  }

  .wordlist-focus {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--heading-color);

    .wordlist__language-selector {
      flex-grow: 2;
      flex-basis: 40%;
    }
  }

  .wordlist-focus__actions {
    display: flex;
    flex-direction: row;
    background-color: $wordlist-language-selector;
    gap: 1rem;
    align-items: center;
    height: 2.75rem;
    padding: 0 1rem;
  }

  .wordlist-focus__action-col {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    flex-basis: 30%;
  }

  .wordlist-focus__action {
    @include button-reset;
    display: flex;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &--return {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1;
      flex-basis: 30%;

      @include mq('medium', 'down') {
        .caption-2 {
          display: none;
        }
      }
    }
  }

  .wordlist-focus__content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}

.standalone-wordlist-container {
  .bc-modal-dialog--open & .bc-dialog__backdrop:last-child {
    position: static;
    padding: 0;
    animation: none;

    .wordlist--standalone {
      animation: none;
      margin: 0;
      max-width: none;

      .bc-dialog__close {
        display: none;
      }
    }
  }
}
