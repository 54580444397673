$thumb-color: hsl(193, 20%, 50%) !default;
$thumb-radius: 0.5rem !default;
$thumb-height: 1.25rem !default;
$thumb-width: 1.25rem !default;

$track-color: hsl(0, 0%, 85%) !default;
$track-height: 0.25rem !default;
$track-radius: 0.25rem !default;

$contrast: 5% !default;

@mixin shadow {
  box-shadow: 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1),
    0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
}

@mixin track {
  height: $track-height;
  background: $track-color;
  border-radius: $track-radius;
  transition: all 0.2s ease;
}

@mixin thumb {
  @include shadow;
  background: $thumb-color;
  border: none;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  height: $thumb-height;
  width: $thumb-width;
  transition: all 0.2s ease;
}

[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  height: 1.75rem;

  &::-webkit-slider-runnable-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: calc($track-height / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include track;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &:disabled {
    opacity: 0.38;
    cursor: not-allowed;
  }

  &:enabled::-moz-range-thumb,
  &:enabled::-moz-range-thumb,
  &:enabled::-moz-range-thumb {
    box-shadow: 0 0 0 0.375rem hsla(193, 20%, 50%, 0.16);
  }

  &:enabled:hover::-webkit-slider-thumb,
  &:enabled:focus::-webkit-slider-thumb,
  &:enabled:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.375rem hsla(193, 20%, 50%, 0.16);
  }
}
