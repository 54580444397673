@use '../abstracts' as *;

.p-no-margin {
  margin: 0 !important;
}
.p-no-margin-bottom {
  margin-bottom: 0 !important;
}

.p-no-padding {
  padding: 0 !important;
}
.p-no-padding-bottom {
  padding-bottom: 0 !important;
}

$spacer: 1rem;

$spacer-100: $spacer * 0.25;
$spacer-200: $spacer * 0.5;
$spacer-300: $spacer * 0.75;
$spacer-400: $spacer;
$spacer-500: $spacer * 1.5;
$spacer-600: $spacer * 2;
$spacer-700: $spacer * 2.5;
$spacer-800: $spacer * 3;
$spacer-900: $spacer * 4;

// comply with old implementation, replace by spacers in the near future
$spacing-steps: (
  1: '0.5rem',
  2: '0.75rem',
  3: '1rem',
);

$spacing-positions: (
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left',
);

$spacing-properties: (
  p: 'padding',
  m: 'margin',
);

@each $propertyShorthand, $property in $spacing-properties {
  @each $positionShorthand, $position in $spacing-positions {
    @each $step, $stepValue in $spacing-steps {
      .p-#{$propertyShorthand}#{$positionShorthand}-#{$step} {
        @include spacer($property: $property, $position: $position, $space: $stepValue);
      }
    }
  }
}

.icon {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.break-word {
  word-wrap: break-word;
}

.lowercase {
  text-transform: lowercase;
}

.row-gap-1 {
  row-gap: 1rem;
}

.overflow-y {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
