@use '../abstracts' as *;

.icon-button {
  @include button-reset;
  border: 0.0625rem solid var(--blue-400);
  border-radius: 0.125rem;
  background-color: var(--white);
  height: 2.5rem;
  padding-inline: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s ease;

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    margin-inline: 0.125rem;
  }

  .bc-svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
  }

  &:hover {
    color: var(--primary-400);
  }

  &--sm {
    width: 2rem;
    height: 2rem;

    .icon {
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
    }
  }

  &:disabled {
    background: var(--grey-300);
    border: 0;
    -webkit-box-shadow: 0 0.125rem 0 0 var(--grey-400);
    box-shadow: 0 0.125rem 0 0 var(--grey-400);
    color: var(--white);
    cursor: not-allowed;
    -webkit-transform: none;
    transform: none;
  }
}
