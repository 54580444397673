@use '../abstracts' as *;

.panel {
  position: relative;

  + .panel {
    margin-top: 1rem;
  }

  &.panel--default {
    background: var(--white);
    border: 0.0625rem solid var(--blue-300);
    padding: 1.5rem;

    .panel__heading {
      @include heading-4;
      margin: 0;
    }

    .panel__heading + .panel__body {
      margin-top: 1.5rem;
    }
  }

  &.panel--default.collapsible {
    .panel__heading {
      color: var(--secondary-400);
    }
  }

  &.panel--primary.collapsible.collapsible--collapsed {
    > .panel__heading {
      background-color: transparent;
    }
  }

  &.panel--default.collapsible:not(.collapsible--collapsed) {
    box-shadow: inset 0.25rem 0 0 0 var(--blue-900);

    &.panel--no-shadow {
      box-shadow: unset;
    }

    .panel__heading {
      color: var(--blue-900);
    }
  }

  &.panel--default.collapsible:not(.collapsible--disabled) {
    .panel__heading {
      &:hover {
        color: var(--blue-900);
      }
    }
  }

  &.panel--primary {
    background: var(--white);
    border: 0.0625rem solid var(--blue-300);

    > .panel__heading {
      @include heading-4;
      background-color: var(--blue-100);
      margin: 0;
      padding: 1.5rem;
    }

    &.collapsible > div > .panel__body,
    > .panel__body {
      padding: 1.5rem;
    }
  }

  &.panel--secondary {
    background: var(--white);
    border: 0.0625rem solid var(--blue-300);
    padding: 1.5rem;
    position: relative;

    .panel__heading {
      @include heading-6;
      background-color: var(--grey-200);
      margin: 0;
      padding: 1rem;
    }

    .panel__heading + .panel__body {
      margin-top: 1.5rem;
    }
  }

  &.panel--tertiary {
    .panel__heading {
      @include caption-2;
      background-color: var(--grey-100);
      border-top: 0.125rem solid var(--blue-300);
      padding: 1rem;
      margin: 0;

      .collapsible__trigger {
        @include uppercase-bold;
      }
    }
  }

  &.panel--small {
    &.panel--default {
      padding: 0 1.6rem;
      margin-bottom: 1rem;

      .panel__heading {
        padding: 1rem 0;
        background-color: transparent;
        font-size: 1rem;
      }

      .panel__body {
        padding: 0;
      }
    }
  }

  &.panel--border-success {
    &:not(.collapsible),
    &.collapsible:not(.collapsible--collapsed) {
      box-shadow: inset 0.25rem 0 0 0 var(--success-400);
    }
  }

  &.panel--border-info {
    &:not(.collapsible),
    &.collapsible:not(.collapsible--collapsed) {
      box-shadow: inset 0.25rem 0 0 0 var(--info-400);
    }
  }

  &.panel--border-warning {
    &:not(.collapsible),
    &.collapsible:not(.collapsible--collapsed) {
      box-shadow: inset 0.25rem 0 0 0 var(--warning-400);
    }
  }

  &.panel--border-danger {
    &:not(.collapsible),
    &.collapsible:not(.collapsible--collapsed) {
      box-shadow: inset 0.25rem 0 0 0 var(--danger-400);
    }
  }

  .panel__heading.has-action {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;

    @include mq('medium') {
      align-items: center;
      flex-direction: row;
    }

    @include mq('medium', 'down') {
      .action {
        flex-basis: 100%;
      }
    }

    .actions-menu {
      font-size: 0.875rem;
      line-height: normal;
      font-weight: 500;
    }
  }
}

.collapsible {
  .collapsible__trigger {
    background: unset;
    border: unset;
    border-radius: unset;
    text-align: unset;
    padding: unset;
    width: 100%;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;

    &:disabled {
      cursor: default;
    }

    &::before {
      // expand clickable area with negative values of padding on the panel--default
      content: '';
      position: absolute;
      top: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      left: -1.5rem;
    }
  }

  .collapsible__title {
    flex-grow: 1;
    display: flex;
  }

  .collapsible__indicator {
    font-size: 1.125rem;
    transition: transform 0.2s linear;
  }
}

.collapsible.collapsible--collapsed {
  .collapsible__indicator {
    transform: rotate(-180deg);
  }
}

.body--bao {
  .panel--default,
  .panel--primary,
  .panel--secondary {
    border-color: var(--blue-200);
  }

  .panel--default.collapsible {
    .panel__heading,
    .collapsible__indicator {
      color: var(--heading-color);
    }
  }

  .panel--default.collapsible:not(.collapsible--collapsed) {
    box-shadow: inset 0.25rem 0 0 0 var(--primary-400);

    .panel__heading {
      color: var(--primary-400);
    }
  }

  .panel--default.collapsible:not(.collapsible--disabled) {
    .panel__heading {
      &:hover {
        color: var(--primary-400);
      }
    }
  }
}
