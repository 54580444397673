@use '../abstracts' as *;

.tooltip-button {
  position: relative;
  display: inline-block;
}

.tooltip-button__button {
  @include button-reset;
  display: flex;
}

.tooltip {
  position: absolute;
  background-color: var(--white);
  border: 0.0625rem solid var(--grey-200);
  box-shadow: 0 0.1875rem 0.375rem var(--grey-300);
  border-radius: 0.25rem;
  width: 28rem;

  &--up {
    right: 0;
    top: -1.25rem;
    transform: translateY(-100%) translateX(10%);

    &::after {
      position: absolute;
      content: '';
      height: 1.25rem;
      width: 1.25rem;
      background-color: var(--white);
      border: 0.0625rem solid var(--grey-200);
      box-shadow: 0 0.1875rem 0.375rem var(--grey-300);
      transform: rotate(-45deg);
      z-index: -1;
      right: calc(10% - 0.125rem);
      bottom: -0.625rem;
    }
  }

  &__content {
    font-size: 0.875rem;
    background-color: var(--white);
    color: var(--body-color);
    padding: 1rem;
  }
}
