@use '../../../abstracts' as *;

.cards {
  --repeat: 1;

  @include mq('medium') {
    --repeat: 2;
  }

  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-columns: repeat(var(--repeat, auto-fit), minmax(10rem, 1fr));
  gap: 1.5rem 1rem;
}

.card {
  @include button-reset;
  transition: all 0.2s ease-in-out;
  border-radius: 0.1875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 0 0.0625rem var(--grey-200), 0 0.1875rem 0.375rem 0 var(--grey-300);
  position: relative;
  height: 100%;

  &__header {
    padding: 1rem 1.5rem;
    border-radius: 0.1875rem 0.1875rem 0 0;
    background-color: var(--sidebar-bg-color);
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
  &__footer {
    padding: 0.5rem 1.5rem;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    font-weight: bold;
    color: var(--white);
    word-break: break-word;
  }

  &__icon {
    height: 1.875rem;
    width: 1.875rem;
    color: var(--primary-400);
    flex-shrink: 0;
  }

  &__score {
    display: flex;
    width: 100%;
    height: 0.3125rem;
    position: relative;

    .bc-progression-bar__container {
      border-radius: unset;
      width: 100%;
      height: 0.3125rem;
    }

    &:hover .score-debug {
      display: block;
    }
  }

  &__usage {
    @include caption-2;
  }

  &:hover {
    transform: translateY(-0.125rem);
    box-shadow: 0 0 0 0.0625rem var(--grey-200), 0 0.1875rem 0.375rem 0 var(--grey-300);

    .card__icon {
      color: var(--white);
    }
  }
}
