.card-flip {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;

  &-wrapper {
    display: block;
    perspective: 3000px;
    transform-style: preserve-3d;
  }

  &-face {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;

    &.card-flip-front {
      z-index: 2;
    }
  }

  &.flipped .card-flip-back {
    z-index: 3;
  }

  &.flip-along-x {
    transform: rotateX(0deg);

    &.flipped,
    .card-flip-back {
      transform: rotateX(180deg);
    }
  }
}
