.message-box {
  padding: 0.75rem 1rem;
  width: 100%;
  border-radius: 0.125rem;
  color: var(--white);
  background-color: var(--grey-400);

  a {
    color: var(--white);
  }

  + * {
    margin-top: 1rem;
  }

  &--success {
    background-color: var(--success-400);
  }

  &--info {
    background-color: var(--info-400);
  }

  &--warning {
    background-color: var(--warning-400);
  }

  &--danger {
    background-color: var(--danger-400);
  }
}
