@use '../base/helpers' as *;

.form-group {
  + .form-group {
    margin-top: $spacer-400;
  }
}

.static-field {
  background-color: var(--grey-200);
  border: 0.0625rem solid var(--blue-400);
  overflow: hidden;
  padding: 0.5rem;
  width: 100%;

  &[disabled] {
    cursor: auto;
  }

  .body--bao & {
    border-radius: 0.3125rem;
    color: var(--blue-500);
    border: 0.0625rem solid var(--blue-200);
  }
}

.checkbox-group {
  display: flex;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
