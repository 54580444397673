@use '../abstracts' as *;

.label {
  @include ellipsis-text;
  border-radius: 0.1875rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--white);
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  display: inline-flex;

  &--info {
    background-color: var(--info-400);
  }

  &--success {
    background-color: var(--success-400);
  }

  &--warning {
    background-color: var(--warning-400);
  }

  &--danger {
    background-color: var(--danger-400);
  }
}
