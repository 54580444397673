@use '../../../abstracts' as *;

.options-modal {
  [class*='heading-'] {
    color: var(--primary-text-color);
  }

  .bc-tabs__tablist {
    margin-bottom: 2rem;
  }

  .bc-tabs__tab {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  #share-tabpanel {
    &.hidden {
      display: none;
    }

    .bc-audience-selector__simplebar {
      height: 230px;
    }

    .bc-searchbox__wrapper {
      margin-bottom: 0.25rem;
    }
  }
}

/** One height across all tabs **/
.options-modal {
  min-height: 40rem;
  display: flex;
  flex-direction: column;

  .bc-dialog__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #share-tabpanel {
    display: flex;
    flex-grow: 1;
  }

  .bc-audience-manager {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;

    &__AudienceSelector {
      flex-grow: 1;
    }
  }
}
