@use '../../../abstracts' as *;

.card--category {
  .category-card__usage {
    @include caption-2;
  }

  &:hover {
    .card__header {
      background-color: var(--primary-400);
    }
  }
}
