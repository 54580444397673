@use '../abstracts' as *;
@use '../base/helpers' as *;

.assignments-table {
  margin: 0;
  padding: 0;
  border-bottom: 0.0625rem solid var(--grey-300);
  color: var(--heading-color);
  overflow-x: auto;

  .assignment {
    display: grid;
    align-items: center;
    grid-column-gap: 0.75rem;
    padding: 0.5rem 1rem;
    list-style: none;
    border-top: 0.0625rem solid var(--grey-300);

    &:hover {
      background-color: var(--grey-100);
      box-shadow: inset 0.1875rem 0 0 0 var(--primary-400);
    }

    &__button {
      @include button-reset;
      display: flex;
      align-self: stretch;
      align-items: center;

      &:hover {
        color: var(--primary-400);
      }

      .bc-svg-icon {
        font-size: 1.5rem;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      font-size: 1.25rem;
      width: 1.5rem;

      .bc-svg-icon {
        font-size: 1.5rem;
      }
    }
  }
}
