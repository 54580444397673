@use '../../abstracts' as *;

.sidebar-scroll-wrapper {
  position: relative;

  .scrollable-area {
    @include no-scrollbar;
    overflow-y: auto;
  }

  &.sidebar-scroll-wrapper--fade {
    &::before {
      content: '';
      background: linear-gradient(transparent, var(--white));
      height: 5rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
    }

    .scrollable-area {
      padding-bottom: 3.75rem;
    }
  }
}

.aside {
  &--browse .scrollable-area {
    max-height: calc(100vh - 4.0625rem - 4rem);
  }

  &--exercise {
    .collapsible-exercise-sections {
      @include no-scrollbar;
      overflow-y: auto;
      max-height: calc(100vh - 4.0625rem);
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &--selected {
        overflow-y: visible;
      }

      .collapsible-section:last-child {
        flex-grow: 1;
      }

      .collapsible-section--book-selector {
        position: relative;
        flex-grow: 1;
      }
    }
  }

  &--results .scrollable-area {
    max-height: calc(100vh - 4.0625rem);
  }

  &--assignments .scrollable-area {
    max-height: calc(100vh - 4.0625rem);
  }
}
